import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ValidationDirective } from './validation/validation.directive';
import { AuthorizeDirective } from './authorize/authorize.directive';
import { PipeModule } from '../pipe/pipe.module';
import { CapitalizePipe } from '../pipe/capitalize.pipe';
import { FormSubmitDirective } from './form/form-submit.directive';
import { FocusDirective } from './focus/focus.directive';
import { TranslatePipe } from '@ngx-translate/core';
import { CurrencyFormatterPipe } from '../pipe/currency-formtter.pipe';

@NgModule({
  declarations: [
    ValidationDirective,
    AuthorizeDirective,
    FormSubmitDirective,
    FocusDirective,
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    ValidationDirective,
    AuthorizeDirective,
    PipeModule,
    FormSubmitDirective,
    FocusDirective
  ],
  providers: [
    CapitalizePipe,
    TranslatePipe,
    CurrencyFormatterPipe
  ],
})
export class DirectiveModule { }
