import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit, OnDestroy {
  @Input('formGroup') formGroup: FormGroup;
  errorMessageTitle: string;
  errorMessages: Array<string>;
  private destroy = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.formGroup.statusChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(s => {
      if (s == "INVALID" && this.formGroup.errors) {
        if (!environment.production) {
          console.log(s);
          console.log(this.formGroup.errors);
        }
        this.errorMessageTitle = this.formGroup.errors.message
        this.errorMessages = [];
        if (this.formGroup.errors?.errorMessages) {
          if (Array.isArray(this.formGroup.errors.errorMessages)) {
            Object.keys(this.formGroup.errors.errorMessages).forEach(error => {
              this.errorMessages.push(this.formGroup.errors.errorMessages[error]);
            });
          } else {
            if (typeof (this.formGroup.errors.errorMessages) == "string") {
              this.errorMessages.push(this.formGroup.errors.errorMessages);
            }
            if (typeof (this.formGroup.errors.errorMessages) == "object") {
              Object.keys(this.formGroup.errors.errorMessages).forEach(error => {
                var errorMsg = this.formGroup.errors.errorMessages[error];
                if (Array.isArray(errorMsg)) {
                  for (var i = 0; i < errorMsg.length; i++) {
                    this.errorMessages.push(errorMsg[i]);
                  }
                } else {
                  this.errorMessages.push(errorMsg);
                }
              });
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  close() {
    this.formGroup.setErrors(null);
  }
}
