import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';

import { UserService } from '../../services/user/user.service';
import { UserRegistrationStatus } from '../../enum/User/user-registration-status';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var result = new Subject<boolean>();

    if (this.authService.isLoggedIn()) {

      return true;

    } else {
      this.authService.redirectUrl = state.url;
      this.router.navigate(['/auth/login']);
      return false;
    }
  }

}
