import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'pp-ask-for-push-notification',
  templateUrl: './ask-for-push-notification.component.html',
  styleUrls: ['./ask-for-push-notification.component.scss']
})
export class AskForPushNotificationComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<AskForPushNotificationComponent>) { }

  ans(ans: boolean): void {
    this._bottomSheetRef.dismiss(ans);
  }

  ngOnInit(): void {
  }

}
