import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreMasterService {

  constructor(
    private http: HttpClient
  ) { }

  getBusinessTypeList(): Observable<Array<BusinessTypeList>> {
    const url = `/api/CoreMaster/GetBusinessTypeForSelection`;
    return this.http.get<Array<BusinessTypeList>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: BusinessTypeList = {
            businessType: item.businessType,
            id: item.id
          };
          return model;
        })
      })
    );
  }

  getSupplierList(): Observable<Array<SupplierList>> {
    const url = `/api/BusinessPartner/GetSuppliers`;
    return this.http.get<Array<SupplierList>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: SupplierList = {
            id: item.id,
            supplierName: item.supplierName
          };
          return model;
        })
      })
    );
  }

  getRetailerList(): Observable<Array<RetailerList>> {
    const url = `/api/BusinessPartner/GetRetailers`;
    return this.http.get<Array<RetailerList>>(url);
  }
  getGetDebtorsList(): Observable<Array<RetailerList>> {
    const url = `/api/BusinessPartner/GetDebtors`;
    return this.http.get<Array<RetailerList>>(url);
  }

  getPaymentTermsList(): Observable<Array<PaymentTerms>> {
    const url = `/api/PaymentTerms/GetAllForSelection`;
    return this.http.get<Array<PaymentTerms>>(url);
  }

  getServiceTypeList(businesstypeid: number): Observable<Array<ServiceType>> {
    const url = `/api/CoreMaster/GetServiceTypeForSelection/${businesstypeid}`;
    return this.http.get<Array<ServiceType>>(url);
  }

  getWarehouseList(): Observable<Array<WarehouseList>> {
    const url = `/api/Warehouse/GetAll/1000/1`;
    return this.http.get<Array<WarehouseList>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: WarehouseList = {
            whcode: item.whcode,
            whname: item.whname,
            whid: item.whid
          };
          return model;
        })
      })
    );
  }
  getReasonCodeList(transactionType: string): Observable<Array<ReasonCode>> {
    const url = `/api/ReasonCode/GetReasonCodeByTransType/${transactionType}`;
    return this.http.get<Array<ReasonCode>>(url).pipe(
      map((resp) => {
        return resp.map((item) => {
          const data: ReasonCode = {
            reasonCode: item.reasonCode,
            reasonCodeId: item.reasonCodeId
          }
          return data;
        })
      })
    );
  }

  getDeliveryTermsList(): Observable<Array<DeliveryTerms>> {
    const url = `/api/CoreMaster/GetDeliveryTermsForSelection`;
    return this.http.get<Array<DeliveryTerms>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: DeliveryTerms = {
            deliveryTerms: item.deliveryTerms,
            deliveryTermsID: item.deliveryTermsID
          };
          return model;
        })
      })
    );
  }

  GetAddressTypeForSelection(): Observable<Array<AddressTypeForSelectionModel>> {
    const url = `/api/CoreMaster/GetAddressTypeForSelection`;
    return this.http.get<Array<AddressTypeForSelectionModel>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: AddressTypeForSelectionModel = {
            addressType: item.addressType,
            addressTypeDes: item.addressTypeDes
          };
          return model;
        })
      })
    );
  }

  getAllCountryList(): Observable<Array<AllCountryModel>> {
    const url = `/api/Country/GetAll/10000/1`;
    return this.http.get<Array<AllCountryModel>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: AllCountryModel = {
            countryCd: item.countryCd,
            countrySd: item.countrySd,
            countryD: item.countryD,
            currencyCd: item.currencyCd,
            languageCd: item.languageCd,
            active: item.active,
            visible: item.visible,
            pinCodeFormat: item.pinCodeFormat,
          };
          return model;
        })
      })
    );
  }

  getStateByCountryList(countrycd: string): Observable<Array<StateByCountryModel>> {
    const url = `/api/State/GetStateByCountry/${countrycd}`;
    return this.http.get<Array<StateByCountryModel>>(url).pipe(
      map((data) => {
        return data.map(item => {
          const model: StateByCountryModel = {
            stateCd: item.stateCd,
            stateD: item.stateD,
            countryCd: item.countryCd,
            active: item.active,
            visible: item.visible,
          };
          return model;
        })
      })
    );
  }
  getAllLanguageList(): Observable<Array<LanguageModel>> {
    const url = `/api/CoreMaster/GetLanguageForSelection`;
    return this.http.get<Array<LanguageModel>>(url);
  }
  getEmmissionCodeList() {
    const url = `/api/CoreMaster/GetEmmissionCodeForSelection`;
    return this.http.get<Array<EmissionCode>>(url);
  }
  getRouteList() {
    const url = `/api/Route/GetAllSelection`;
    return this.http.get<Array<Route>>(url);
  }

  getHauliersDropDown(): Observable<Array<HauliersDropDown>> {
    const url = "/api/BusinessPartner/GetHauliers";
    return this.http.get<Array<HauliersDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: HauliersDropDown = {
            id: item.id,
            hauilierName: item.hauilierName
          }
          return data;
        })
      })
    );
  }

  getPostCodeDropDown(): Observable<Array<PostCodeDropDown>> {
    const url = "/api/CoreMaster/GetPostCodeForSelection";
    return this.http.get<Array<PostCodeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: PostCodeDropDown = {
            postCodeId: item.postCodeId,
            postCode: item.postCode
          }
          return data;
        })
      })
    );
  }
  getTransportTypeDropDown(): Observable<Array<TransportTypeDropDown>> {
    const url = "/api/CoreMaster/GetTransportTypeForSelection";
    return this.http.get<Array<TransportTypeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: TransportTypeDropDown = {
            transPrcId: item.transPrcId,
            description: item.description
          }
          return data;
        })
      })
    );
  }
  getUOMDropDown(): Observable<Array<UOMDropDown>> {
    const url = "/api/CoreMaster/GetUOMForSelection";
    return this.http.get<Array<UOMDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: UOMDropDown = {
            uomcd: item.uomcd,
            uomdesc: item.uomdesc
          }
          return data;
        })
      })
    );
  }
  getProductDropDown(): Observable<Array<ProductDropDown>> {
    const url = "/api/Product/GetAllForSelection";
    return this.http.get<Array<ProductDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: ProductDropDown = {
            productId: item.productId,
            prodName: item.prodName
          }
          return data;
        })
      })
    );
  }


  getDocumentTypeDropDown(): Observable<Array<DocumentTypeDropDown>> {
    const url = "/api/CoreMaster/GetDocumentTypeForSelection";
    return this.http.get<Array<DocumentTypeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: DocumentTypeDropDown = {
            docTypeID: item.docTypeID,
            doctype: item.doctype
          }
          return data;
        })
      })
    );
  }

  getEmployeeTypeDropDown(): Observable<Array<EmployeeTypeDropDown>> {
    const url = "/api/CoreMaster/GetEmployeeTypeForSelection";
    return this.http.get<Array<EmployeeTypeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: EmployeeTypeDropDown = {
            employeeTypId: item.employeeTypId,
            employeeTypDesc: item.employeeTypDesc
          }
          return data;
        })
      })
    );
  }


}

export interface Route {
  routeId: number;
  routeName: string;
  routeCode: string;
}

export interface EmissionCode {
  emissionCode: string;
  description: string;
}

export interface ServiceType {
  serviceTypeId: number;
  serviceType: string;
}
export interface PaymentTerms {
  id: number;
  paymentTermsCd: string;
  paymentTermsDes: string;
}

export interface WarehouseList {
  whcode: string;
  whid: number;
  whname: string;
}

export interface BusinessTypeList {
  id: number;
  businessType: string;
}

export interface SupplierList {
  id: number;
  supplierName: string;
}
export interface RetailerList {
  id: number;
  retailerName: string;
  retailerCode: string;
}
export interface ReasonCode {
  reasonCodeId: string;
  reasonCode: string;
}
export interface DeliveryTerms {
  deliveryTermsID: number;
  deliveryTerms: string;
}

export interface AddressTypeForSelectionModel {
  // $id: string;
  addressType: string;
  addressTypeDes: string;
}

export interface AllCountryModel {
  // $id: string;
  countryCd: string;
  countrySd: string;
  countryD: string;
  currencyCd: string;
  languageCd: string;
  active: boolean;
  visible: boolean;
  pinCodeFormat: string;
}

export interface StateByCountryModel {
  // $id: string;
  stateCd: string;
  stateD: string;
  countryCd: string;
  active: boolean;
  visible: boolean;
}

export interface LanguageModel {
  // $id: string;
  languageCd: string;
  languageSd: string;
  languageD: string;
}

export interface DocumentTypeDropDown {
  docTypeID: number,
  doctype: string
}

export interface TransportTypeDropDown {
  transPrcId: number,
  description: string
}
export interface UOMDropDown {
  uomcd: number,
  uomdesc: string
}
export interface ProductDropDown {
  productId: number,
  prodName: string
}

export interface HauliersDropDown {
  id: number,
  hauilierName: string
}
export interface PostCodeDropDown {
  postCodeId: number,
  postCode: string
}
export interface EmployeeTypeDropDown {
  employeeTypId: number,
  employeeTypDesc: string
}
