import { Injectable } from '@angular/core';

import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';

import { LanguageService } from './../../services/language/language.service';

@Injectable()
export class AuthModuleLanguageResolver implements Resolve<number> {

    constructor(
        private translateService: TranslateService,
        private languageService: LanguageService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {

        this.translateService.set('login.caption', 'User Login', 'en');
        this.translateService.set('login.username', 'Username', 'en');
        this.translateService.set('login.password', 'Password', 'en');
        this.translateService.set('login.corporateid', 'Corporate ID', 'en');
        this.translateService.set('login.button', 'Login', 'en');
        this.translateService.set('login.forgetpassword', 'Forget Password?', 'en');
        this.translateService.set('login.loading', 'Logging In...', 'en');
        this.translateService.set('login.logout-message', 'You have successfully logged off', 'en');
        this.translateService.set('login.re-login-text', 'Click here to', 'en');
        this.translateService.set('login.re-login-link', 'login', 'en');
        this.translateService.set('login.session-expired-message', `Your session has expired !`, 'en');

        this.translateService.set('forgetPassword.UserName', 'UserName', 'en');
        this.translateService.set('forgetPassword.Send', 'Send', 'en');

        this.translateService.set('changePassword.NewPassword', 'New password', 'en');
        this.translateService.set('changePassword.ConfirmPassword', 'Confirm password', 'en');
        this.translateService.set('ConfirmPassword.Submit', 'Submit', 'en');
        this.translateService.set('login.confirmPwdNotMatch', 'Confirm password not match', 'en');
        this.translateService.set('login.confirmPwdRequired', 'Confirm password is required', 'en');

        return this.languageService.loadLocalResource('login');
    }
}