import { Directive, ElementRef, Output, EventEmitter, ChangeDetectorRef, OnInit, Input, OnDestroy, ViewChild, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'form'
})
export class FormSubmitDirective implements OnInit, OnDestroy {
  private stopValidating: boolean;
  private destroy = new Subject<void>();
  @Input('formGroup') formGroup: FormGroup;
  @Output('isValid') isFormValid: EventEmitter<boolean>;
  submit$ = fromEvent(this.elementRef.nativeElement, 'submit');

  constructor(
    private elementRef: ElementRef<HTMLFormElement>,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.isFormValid = new EventEmitter<boolean>();
  }


  ngOnInit() {
    this.submit$.pipe(takeUntil(this.destroy))
      .subscribe(async (s) => {
        s.preventDefault();

        try {
          const isValid = await this.isValid(this.formGroup, this.elementRef.nativeElement);
          this.isFormValid.emit(isValid);
        } catch (e) {
          console.log(e)
          this.isFormValid.emit(this.formGroup.valid);
        }
      })
  }

  async isValid(form: FormGroup, element: HTMLElement): Promise<boolean> {
    if (form.pending) {
      if (form.valid) {
        return this.showError(form, element);
      }
      return false;
    }
    return this.showError(form, element);
  }

  private async showError(form: FormGroup, element: HTMLElement): Promise<boolean> {
    if (form.invalid) {

      this.markDirtyAndTouchAllControl(form);

      let fields: Array<string> = [];
      this.stopValidating = false;

      try {
        await this.focusOnInvalidControls(form, element, fields);
      } catch (e) {
        console.warn("unable to focus on invalid control");
      }

      return form.valid;
    }

    return form.valid;
  }

  private markDirtyAndTouchAllControl(controls: FormGroup | FormControl) {

    if (controls instanceof FormArray) {

      const controlList = controls as FormArray;

      controlList.markAsDirty();
      controlList.markAsTouched();
      controlList.updateValueAndValidity();
      for (let i = 0; i < controlList.length; i++) {
        const abstractControl = controlList[i];
        if (abstractControl) {
          this.markDirtyAndTouchAllControl(abstractControl);
        }
      }
    } else if (controls instanceof FormGroup) {

      Object.keys(controls.controls).forEach((key: string) => {

        const abstractControl = controls.controls[key];

        if (abstractControl instanceof FormGroup) {

          this.markDirtyAndTouchAllControl(abstractControl);

        } else if (abstractControl instanceof FormControl) {

          abstractControl.markAsDirty();
          abstractControl.markAsTouched();
          abstractControl.updateValueAndValidity();

        } else if (abstractControl instanceof FormArray) {
          const abstractControlList = abstractControl as FormArray;
          abstractControlList.markAsDirty();
          abstractControlList.markAsTouched();
          abstractControlList.updateValueAndValidity();
          for (let j = 0; j < abstractControlList.controls.length; j++) {
            const abstractControl = abstractControlList[j];
            if (abstractControl) {
              this.markDirtyAndTouchAllControl(abstractControl);
            }
          }
        } else {
          console.warn("conditions not matched");
        }

      });

    } else if (controls instanceof FormControl) {
      controls.markAsDirty();
      controls.markAsTouched();
      controls.updateValueAndValidity();
    }
  }


  private focusOnInvalidControls(controls: FormGroup | FormControl, element: HTMLElement, selectorList: Array<string>, parentSelector: string = null): Promise<void> {
    return new Promise(async (resolve, rejects) => {

      if (controls instanceof FormGroup) {

        Object.keys(controls.controls).every(async (key: string) => {

          const abstractControl = controls.controls[key];

          if (abstractControl instanceof FormGroup) {

            if (parentSelector === null) {

              parentSelector = `${key}.`;

            } else {
              parentSelector += `${key}.`;
            }
            this.focusOnInvalidControls(abstractControl, element, selectorList, parentSelector);
            parentSelector = null;
          } else if (abstractControl instanceof FormControl) {

            if (this.stopValidating) {
              resolve();
              return true;
            }

            if (parentSelector === null) {
              selectorList.push(`[formcontrolname='${key}']`);
              const isValid = await this.validateControls(abstractControl, element, `[formcontrolname='${key}']`);

              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            } else {
              let selector = "";
              const depth = parentSelector.split(".");
              for (let i = 0; i < depth.length - 1; i++) {
                selector += ` [formgroupname='${depth[i]}']`;
              }
              selector += ` [formcontrolname='${key}']`;
              selectorList.push(selector);
              const isValid = await this.validateControls(abstractControl, element, selector);

              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            }
            resolve()
          }
        });

      } else if (controls instanceof FormControl) {
        
        Object.keys(controls).every(async (key: string) => {
          const abstractControl = controls[key];
          if (parentSelector == null) {
            selectorList.push(`[formcontrolname='${key}']`);
          } else {
            let selector = "";
            const depth = parentSelector.split(".");

            for (let i = 0; i < depth.length - 1; i++) {
              selector += ` [formgroupname='${depth[i]}']`;
            }

            selector += ` [formcontrolname='${key}']`;
            selectorList.push(selector);
            const isValid = await this.validateControls(abstractControl, element, selector);

            if (!isValid) {
              this.stopValidating = true;
              resolve();
              return true;
            }
          }
        })

      }

    });

  }

  private async isAsyncValid(controls: AbstractControl): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let wait = setInterval(() => {
        if (controls.pending == false) {
          clearInterval(wait);
          resolve(true)
        }
      }, 100)
    })
  }

  private validateControls(property: AbstractControl, element: HTMLElement, selector: string): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      if ((property.invalid || !property.valid) && !property.pending) {
        const input = element.querySelector(selector) as HTMLInputElement;
        if (input && input.focus instanceof Function) {
          if (this.stopValidating) {
            resolve(false);
          } else {
            this.stopValidating = true;
            input.focus();
            this.cd.detectChanges();
            resolve(false);
          }
        }
      } else {
        if (property.pending) {
          await this.isAsyncValid(property);
          if ((property.invalid || !property.valid) && !property.pending) {
            const input = element.querySelector(selector) as HTMLInputElement;
            if (input && input.focus instanceof Function) {
              if (this.stopValidating) {
                resolve(false);
              } else {
                this.stopValidating = true;
                input.focus();
                this.cd.detectChanges();
                resolve(false);
              }
            }
          }
        }
      }
      resolve(true);
    });

  }
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
