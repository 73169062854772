import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AskForPushNotificationComponent } from './component/shared/ask-for-push-notification/ask-for-push-notification.component';
import { PushNotificationService } from './services/push-notification/push-notification.service';
import { AuthService } from './services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PackPlan';

  constructor(
    private _bottomSheet: MatBottomSheet,
    private pushNotificationService: PushNotificationService,
    private authService: AuthService,
    private translate: TranslateService
  ) {

    translate.addLangs(['en', 'fr', 'de-DE']);
    translate.setDefaultLang('en');

  }
  ngOnInit() {
    if ("Notification" in window) {
      if (Notification.permission === "default" && localStorage.getItem('askForPush') == undefined) {
        setTimeout(() => {
          if (this.authService.isLoggedIn()) {
            const ref = this._bottomSheet.open(AskForPushNotificationComponent);
            ref.afterDismissed().subscribe(ans => {
              if (ans == true) {
                this.pushNotificationService.requestPermission();
              } else if (ans == false) {
                localStorage.setItem('askForPush', 'no');
              }
            })
          }
        }, 10000)
      }
    }
  }
}
