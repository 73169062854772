<div class="wrapper">
    <mat-progress-bar color="primary" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="sidebar" data-color="blue" data-background-color="white">
        <pp-sidebar></pp-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <pp-header></pp-header>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
        <div *ngIf="!isMap()">
            <pp-footer></pp-footer>
        </div>
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
</div>