// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: "https://api.packplan.co.uk",
 apiUrl: "https://api.packplan.net",
  firebase: {
    apiKey: "AIzaSyAi10WWAlThPShGuV92zFDuodUvlVlsWN0",
    authDomain: "packplan-5eda4.firebaseapp.com",
    databaseURL: "https://packplan-5eda4.firebaseio.com",
    projectId: "packplan-5eda4",
    storageBucket: "packplan-5eda4.appspot.com",
    messagingSenderId: "849210832014",
    appId: "1:849210832014:web:24a7ba93e5b200ef45c5df",
    measurementId: "G-ECKEB0XYK6"
  },
  CryptoJS: {
    key: '5056483776328765',
    iv: '5056483776328765',
    keySize: 128 / 8
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
