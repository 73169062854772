import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import {
  
  WarehouseService,
  WHLocationslotModel,
  WHLocationModel
} from 'src/app/services/warehouse/warehouse.service';
import { AlltransactionsdtlComponent } from '../alltransactionsdtl.component';

import { FormGroup,NgForm } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'pp-whstockslotmanagement',
  templateUrl: './whstockslotmanagement.component.html',
  styleUrls: ['./whstockslotmanagement.component.scss'],
  providers: [
    MatTabGroup,
       
    MatDialog
  ],
})
export class WhstockslotmanagementComponent implements OnInit {
  whslotdata: Array<WHLocationslotModel> = [];
  pulledDispatchOrderDetail: Array<any>;
  @ViewChild('slotmanagementFormTemplate') slotmanagement: NgForm;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WhstockslotmanagementComponent>,
    private warehouseService: WarehouseService,
    private dialog: MatDialog,
    
    @Inject(MAT_DIALOG_DATA) public LName: any,
    @Inject(MAT_DIALOG_DATA) public whid: any,
    @Inject(MAT_DIALOG_DATA) public bpid: any,

  ) { }
  public whLocationData: Array<WHLocationModel>;
  warehouseslot: FormGroup;
  ngOnInit(): void {

    this.getWHLocationData();
  this.GetAllLocationSlot(); 
  this.GetProductByDispatchOrderID();
  }
  
  GetAllLocationSlot() {
    debugger;
   this.warehouseService
    .GetWareHouseLocSlotsWHIdandLocationID(this.whid.whid,this.LName.LName)
   .subscribe((Data) => {
      this.whslotdata = Data;
      console.log(this.whslotdata);
      

     //this.loading = false;
     })
  }GetProductByDispatchOrderID() {
    debugger;
    this.warehouseService
    .GetDispatchOrderByID(this.bpid.bpid)
   .subscribe((Data) => {
      this.pulledDispatchOrderDetail = Data.dispatchOrderDetail;
      console.log(this.pulledDispatchOrderDetail);
      

     //this.loading = false;
     })
  }
  getWHLocationData() {
    debugger;
   this.warehouseService
    .GetWarehouseLocationByWhId(this.whid.whid)
   .subscribe((Data) => {
      this.whLocationData = Data;
      console.log(this.whLocationData);
     //this.loading = false;
     })
  }


  locationSelected: number;
  onStorageLocationChange(event: MatSelectChange) {
    this.locationSelected = event.value;
    debugger;
    if (event.value > 0) {
      this.loading = true;
      this.warehouseService
      .GetWareHouseLocSlotsWHIdandLocationID(this.whid.whid,this.locationSelected)
      .subscribe((Data) => {
         this.whslotdata = Data;
         console.log(this.whslotdata);
        //this.loading = false;
        })

        this.loading = false;
     }
    }

  onNoClick(): void {
  this.dialogRef.close();
  }
  openSlotstrandtl() {
    
    console.log('****', 'click');
    const DailogRef = this.dialog.open(AlltransactionsdtlComponent, {
      width: '70%',
      maxWidth: '100%',
      maxHeight: '100%',
      role: 'alertdialog',
      disableClose: true
    //  data:LName
     
    });
  }

}
