<div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <ul class="breadcrumb mb-3">
          <li>Logistic</li>
          <li></li>
          <li>
            <a href="#"></a>
            List
          </li>
        </ul>
      </div>
    
      <div class="col-12">
        <mat-card class="mb-5">
          <mat-card-content class="pb-3">
            <ejs-grid
              #grid
              id="PagingGrid"
            
              [dataSource]="StCorrectionlist"

              [allowSorting]="true"
              [allowPaging]="true"
           
              [allowExcelExport]="true"
              [allowPdfExport]="true"
             
          
            >
              <e-columns>
              
               
                <e-column
                  [customAttributes]="customAttributes"
                  field="orderDate"
                  [headerText]="'ord.order-date' | translate"
                  textAlign="left"
                  width="140"
                ></e-column>
  
                <e-column
                  [customAttributes]="customAttributes"
                  field="deliveryDate"
                  [format]="dateFormat"
                  [headerText]="'ostc.correction.product' | translate"
                  textAlign="left"
                  width="140"
                ></e-column>
                <e-column
                [customAttributes]="customAttributes"
                field="businessType"
                [headerText]="'ostc.slotno.name' | translate"
                textAlign="left"
                width="140"
              ></e-column>
                <e-column
                  [customAttributes]="customAttributes"
                  field="bpname"
                  [headerText]="'ostc.correction.status-name' | translate"
                  textAlign="left"
                  width="160"
                ></e-column>
                <e-column
                  [customAttributes]="customAttributes"
                  field="transport"
                  [headerText]="'ostc.correction.NewQty' | translate"
                  textAlign="left"
                  width="140"
                ></e-column>
                <e-column
                  [customAttributes]="customAttributes"
                  field="crn"
                  [headerText]="'ostc.correction.NewQty' | translate"
                  textAlign="left"
                  width="250"
                ></e-column>
  
              
              </e-columns>
            </ejs-grid>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  