import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { interval, merge, Observable, Subject, Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user';
import { Router } from '@angular/router';

import { HeaderComponent, QuickAction } from '../header/header.component';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { trace } from 'console';
import {
  NotificationService,
  UserNotification,
} from './../../../services/notification/notification.service';
@Component({
  selector: 'pp-warehouse-layout',
  templateUrl: './warehouse-layout.component.html',
  styleUrls: ['./warehouse-layout.component.scss']
})
export class WarehouseLayoutComponent implements OnInit {
  currentUser: User;

  currentDate: Date;
  isbackvisible:boolean=false;
  public menuItems: any[];
  quickActionList: Array<QuickAction> = [];
  apiSearch: Subject<Array<QuickAction>>;
  quickActionResult: Observable<Array<QuickAction>>;
  isLoading:Boolean=false;
  subscription: Subscription;
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private communicationService: CommunicationService,
    private authService: AuthService
  ) {
   
  }
  ngOnInit(): void {
    this.currentUser = this.authService.currentUser();
    this.currentUser = this.authService.currentUser();
    if(this.currentUser.whid!=null)
    {
      this.isbackvisible=false;
    }
    else
    {
      this.isbackvisible=true;
    }
    const secondsCounter = interval(1000);
    this.subscription = secondsCounter.subscribe((n) => {
      this.currentDate = new Date();
      this.isLoading=false;
    });
  }
  logout() {
    
    this.authService.logout('logout');
  }
  BackButton() {
    this.router.navigate(['/']);
  }
  Dashboard() {
    this.isLoading=true;
    this.router.navigate(['/warehouse/dashboard']);
  }
  navigateGRN() {
    this.isLoading=true;
   this.router.navigate(['/warehouse/production/goods-receipt']); 
  }
  navigatetodayplw() {
    this.isLoading=true; 
      this.router.navigate(['/warehouse/production/todaypl']);    
  }
  navigateplanning() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/planningw']);   
  }
  navigateinsp() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/inspection']);   
  }
  navigatesorting() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/sorting']);   
  }
  navigatewash() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/Wash']);   
  }
  navigateRepair() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/Repair']);   
  }
  navigateGIN() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/goods-issue']);   
  }
  navigateSSheet() {
    this.isLoading=true;
      this.router.navigate(['/warehouse/production/Stocksheet']);   
  }
  private _quickActionFilter(value: string): Array<QuickAction> {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.quickActionList.filter(
        (option) =>
          option.ab?.toLowerCase().includes(filterValue) ||
          option.text.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }
  
  searchNotification(searchTerm: string) {
    this.menuItems = this.communicationService.getDepotMenuList();
    console.log('apiSearch q', searchTerm, this.menuItems);

    if (searchTerm && searchTerm.length > 2) {
      this.notificationService
        .searchNotification(searchTerm)
        .subscribe((searchResult) => {
          let quickAction: Array<QuickAction> =
            this._quickActionFilter(searchTerm);

          searchResult.notification.forEach((item) => {
            switch (
              item.refDocTypeId
              //purchase requisition
              // case 91:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/requisition/edit/${item.refDocNumber}`,
              //     type: 'REQ',
              //   });
              //   break;
              // //Request for Quotation
              // case 92:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/request-for-quotation/edit/${item.refDocNumber}`,
              //     type: 'RFQ',
              //   });
              //   break;
              // //Vendor Quotation
              // case 93:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/vendor-offer/edit/${item.refDocId}`,
              //     type: 'VEO',
              //   });
              //   break;
              //Purchase Order
              // case 94:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/order/edit/${item.refDocNumber}`,
              //     type: 'POR',
              //   });
              //   break;
            ) {
            }
          });

          // searchResult.productresult.forEach((item) => {
          //   quickAction.push({
          //     text: item.productDetail,
          //     link: `/master/product/edit/${item.productId}`,
          //     type: 'PRODUCT',
          //   });
          // });

          // searchResult.busiesspartner.forEach((item) => {
          //   quickAction.push({ text: item.bpname, link: ``, type: 'BP' });
          // });

          this.apiSearch.next(quickAction);
        });
    }
  }
}
