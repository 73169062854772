import { Component, OnInit, Inject } from '@angular/core';
import { ReasonCode, CoreMasterService } from 'src/app/services/core-master/core-master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pp-transaction-cancel-dialog',
  templateUrl: './transaction-cancel-dialog.component.html',
  styleUrls: ['./transaction-cancel-dialog.component.scss']
})
export class TransactionCancelDialogComponent implements OnInit {

  reasonCodes: Array<ReasonCode> = [];
  requisitionCancelForm: FormGroup;

  constructor(
    private coremasterService: CoreMasterService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TransactionCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit(): void {

    this.requisitionCancelForm = this.fb.group({
      reason: ['', Validators.required],
      remarks: [''],
    });

    this.coremasterService.getReasonCodeList("req").subscribe(data => {
      this.reasonCodes = data;
    })
  }
  onNoClick() {
    this.dialogRef.close(null);
  }

  cancelRequisition(isValid: boolean) {
    if (isValid) {
      console.log(this.requisitionCancelForm.value);
      this.dialogRef.close(this.requisitionCancelForm.value);
    }
  }

}
