import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { MaterialModule } from '../../../../module/material/material.module';
import { PipeModule } from '../../../../pipe/pipe.module';
import { DirectiveModule } from '../../../../directive/directive.module';
import { DefaultErrorStateMatcher } from '../../../../directive/validation/validation.directive';
import { ErrorStateMatcher } from '@angular/material/core';
import { OpeningStockCorrectionModule } from './../opening-stock-correction.module';
import { WarehouseModule } from '../../warehouse.module';
import { SyncfusionGridModule } from '../../../../module/grid/syncfusion-grid.module';
import { StockCorrectionListComponent } from './stock-correction-list.component';
// import { TransactionCancelDialogComponent} from '../../../shared/transaction-cancel-dialog/transaction-cancel-dialog.component'

@NgModule({
  declarations: [StockCorrectionListComponent],
  imports: [
    CommonModule,
    
    MaterialModule,
    DirectiveModule,
    PipeModule,
    OpeningStockCorrectionModule,
    
    WarehouseModule,
    SyncfusionGridModule,
    
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: DefaultErrorStateMatcher }
  ],
})
export class OpeningStockCorrectionListModule { }
