import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { TranslateModule } from '@ngx-translate/core';

import { FormErrorComponent } from './../../component/shared/form-error/form-error.component';
import { InternalServerErrorComponent } from './../../component/shared/internal-server-error/internal-server-error.component';
import { LoadingComponent } from './../../component/shared/loading/loading.component';
import { PageNotFoundComponent } from './../../component/shared/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './../../component/shared/permission-denied/permission-denied.component';
import { OfflineComponent } from './../../component/shared/offline/offline.component';
import { SomethingWentWrongComponent } from './../../component/shared/something-went-wrong/something-went-wrong.component';
import { FileUploadComponent } from './../../component/shared/file-upload/file-upload.component';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({

  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild()
  ],
  declarations: [
    FormErrorComponent,
    InternalServerErrorComponent,
    LoadingComponent,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    OfflineComponent,
    SomethingWentWrongComponent,
    FileUploadComponent,
  ],
  exports: [
    MatFormFieldModule,
    FormErrorComponent,
    InternalServerErrorComponent,
    LoadingComponent,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    OfflineComponent,
    SomethingWentWrongComponent,
    FileUploadComponent,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatBadgeModule,
    AutocompleteLibModule,
    MatBottomSheetModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
    //{ provide: MAT_DATE_FORMATS, useValue: MY_NATIVE_DATE_FORMATS },
  ]
})
export class MaterialModule { }
