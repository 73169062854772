import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { settings } from 'src/app/settings/settings';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  errorMessage: boolean;
  constructor(
    public authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    if (request.url.indexOf("http://") > -1 || request.url.indexOf("https://") > -1) {
      return next.handle(request);
    } else {
      var
        request = request.clone({
          url: `${environment.apiUrl}${this.ensureCorporateIdAndToken(request.url)}`,
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`,
            corpId: this.authService.getCorporateId(),
            UserToken: this.authService.getToken() ? this.authService.getToken() : "",
            culture: this.translateService.getBrowserCultureLang()
          }
        });

      return next.handle(request)
        .pipe(
          tap((evt: HttpEvent<any>) => {
            if (this.errorMessage) {
              this._snackBar.dismiss();
              this.errorMessage = false;
            }
          },
            err => {
              if (err instanceof HttpErrorResponse) {
                this.errorMessage = true;
                switch (err.status) {
                  case 0:
                    //this.router.navigate(['/something-went-wrong']);
                    this._snackBar.open(settings.httpErrorMessage.httpFailure, 'Close', {
                      duration: 10000,
                    });
                    break;
                  case 401:
                    if (!request.url.includes('/api/Auth/Login')) {
                      this.authService.logout('sessionExpaired');
                    }
                    break;
                  case 500:
                    this._snackBar.open(settings.httpErrorMessage.internalServerError, 'Close', {
                      duration: 10000,
                    });
                    //this.router.navigate(['/internal-server-error'])
                    break;
                  default:
                }

              }
            }
          )
        );
    }
  }

  // private addToken(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     url: `${request.url.replace(/{tokenkey}/g, token)}`,
  //     setHeaders: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   });
  // }

  private ensureCorporateIdAndToken(url: string) {
    return url.replace(/{tokenkey}/g, this.authService.getToken())
      .replace(/{corpId}/g, this.authService.getCorporateId());
  }
}
