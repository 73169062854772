import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../../services/permission/permission.service';

@Directive({
    selector: '[ngAuthorize]'
})
export class AuthorizeDirective {
    @Input('ngAuthorize') AuthorizeName: string;
    isVisible = false;
    constructor(private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionService:PermissionService
    ) { }

    ngOnInit() {
        if (this.AuthorizeName) {
            if (this.permissionService.isAllowed(this.AuthorizeName)) {
                if (!this.isVisible) {
                    this.isVisible = true;
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            } else {
                this.isVisible = false;
                this.viewContainerRef.clear();
            }
        } else {
            throw new Error("AuthorizeName is not defined");
        }
    }
}
