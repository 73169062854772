import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridModule, PagerModule } from '@syncfusion/ej2-angular-grids';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    GridModule,
    PagerModule
  ]
})
export class SyncfusionGridModule { }
