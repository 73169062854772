import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { of, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    //common resource
    this.translateService.set('common.create', `Create`, 'en')
    this.translateService.set('common.basic', `Basic`, 'en')
    this.translateService.set('common.preview', `Preview`, 'en')
    this.translateService.set('common.confirm', `Confirm`, 'en')
    this.translateService.set('common.add', `+ Add one more`, 'en')
    this.translateService.set('common.close', `Close`, 'en')
    this.translateService.set('common.update', `Update`, 'en')
    this.translateService.set('common.back', `Back`, 'en')
    this.translateService.set('common.save', `Save`, 'en')
    this.translateService.set('common.next', `Next`, 'en')
    this.translateService.set('common.empty', `Empty`, 'en')
    this.translateService.set('common.quantity', `Quantity`, 'en')
    this.translateService.set('common.prod.quantity', `Prod Quantity`, 'en')
    this.translateService.set('common.tbwqty', `TBW Quantity`, 'en')
    this.translateService.set('common.qty', `Qty`, 'en')
    this.translateService.set('common.in-stock-quantity', `In-Stock`, 'en')
    this.translateService.set('common.product', `Product`, 'en')
    this.translateService.set('common.remarks', `Remarks`, 'en')
    this.translateService.set('common.created-by', `Created By`, 'en')
    this.translateService.set('common.status', `Status`, 'en')
    this.translateService.set('common.action', `Action`, 'en')
    this.translateService.set('common.view', `View`, 'en')
    this.translateService.set('common.edit', `Edit`, 'en')
    this.translateService.set('common.cancel', `Cancel`, 'en')
    this.translateService.set('common.repeat', `Repeat`, 'en')
    this.translateService.set('common.reminder', `Reminder`, 'en')
    this.translateService.set(
      'common.mustBeGraterThan',
      `must be grater than`,
      'en',
    )
    this.translateService.set(
      'common.mustBePositiveValue',
      `must be positive value`,
      'en',
    )
    this.translateService.set('common.cantLessThan', `can't less than`, 'en')
    this.translateService.set('common.maxUpto', `max upto`, 'en')
    this.translateService.set('common.charactersLong', `characters long`, 'en')
    this.translateService.set('common.mustBeAtLeast', `must be at least`, 'en')
    this.translateService.set('common.isNotValid', `is not valid`, 'en')
    this.translateService.set('common.isRequired', `is required`, 'en')
    this.translateService.set('common.requisition', `Requisition`, 'en')
    this.translateService.set('common.request-by', `Request By`, 'en')
    this.translateService.set('common.description', `Description`, 'en')
    this.translateService.set('common.welcome', `Welcome`, 'en')
    this.translateService.set('common.date', `Date`, 'en')
    this.translateService.set('common.time', `Time`, 'en')
    this.translateService.set('common.loading', `Loading…`, 'en')
    this.translateService.set('common.processing', `Processing…`, 'en')
    this.translateService.set('common.unknown', `Unknown`, 'en')
    this.translateService.set('common.yes', `Yes`, 'en')
    this.translateService.set('common.no', `No`, 'en')
    this.translateService.set('common.reason', `Reason`, 'en')
    this.translateService.set('common.vendor-offer', `Vendor Offer`, 'en')
    this.translateService.set('common.total', `Total`, 'en')
    this.translateService.set('common.approve', `Approve`, 'en')
    this.translateService.set('common.print', `Print`, 'en')
    this.translateService.set('common.printing', `Printing`, 'en')
    this.translateService.set(
      'common.link-not-valid',
      `Link is not valid`,
      'en',
    )
    this.translateService.set(
      'common.unable-to-process',
      `unable to process at this moment, please try later`,
      'en',
    )
    this.translateService.set('common.thank-you', `Thank You !`, 'en')
    this.translateService.set('common.confirming', `Confirming…`, 'en')
    this.translateService.set(
      'common.action-canceled',
      `Action cancelled by user`,
      'en',
    )
    this.translateService.set('common.sl', `SL`, 'en')
    this.translateService.set('common.reject', `Reject`, 'en')
    this.translateService.set('common.accept', `Accept`, 'en')
    this.translateService.set('common.price', `Price`, 'en')
    this.translateService.set(
      'common.unable-to-load-data',
      `Unable to load data, please try after sometime.`,
      'en',
    )
    this.translateService.set('common.quotation', `Quotation`, 'en')
    this.translateService.set('common.upload-file', `Upload File`, 'en')
    this.translateService.set('common.files-selected', `files selected`, 'en')
    this.translateService.set('common.document', `Document`, 'en')
    this.translateService.set('common.name', `Name`, 'en')
    this.translateService.set('common.business-type', `Business Type`, 'en')
    this.translateService.set(
      'common.business-partner',
      `Business Partner`,
      'en',
    )
    this.translateService.set('common.sub-type', `Sub Type`, 'en')
    this.translateService.set('common.delivery-terms', `Delivery Terms`, 'en')
    this.translateService.set('common.rate', `Rate`, 'en')
    this.translateService.set('common.hour', `Hour`, 'en')
    this.translateService.set('common.minute', `Minute`, 'en')
    this.translateService.set('common.transport', `Transport`, 'en')
    this.translateService.set('common.crn', `CRN`, 'en')
    this.translateService.set('common.delivery-date', `Delivery Date`, 'en')
    this.translateService.set('common.collection-date', `Collection Date`, 'en')
    this.translateService.set('common.delivery-time', `Delivery Time`, 'en')
    this.translateService.set('common.order-no', `Order No`, 'en')
    this.translateService.set(
      'common.prod-order-no',
      `Production Order No`,
      'en',
    )
    this.translateService.set('common.desp-order-no', `Despatch Order No`, 'en')
    this.translateService.set(
      'common.prod-order-no',
      `Production Order No`,
      'en',
    )
    this.translateService.set('common.order-date', `Order Date`, 'en')
    this.translateService.set('common.collection-no', `Collection  No`, 'en')

    this.translateService.set(
      'common.duplicate-entry',
      `Duplicate entry is not allowed`,
      'en',
    )
    this.translateService.set('common.haulier', `Haulier`, 'en')
    this.translateService.set(
      'common.multiple-selection-not-allowed',
      `Multiple selection are not allowed`,
      'en',
    )
    this.translateService.set('common.collection-date', `Collection Date`, 'en')
    this.translateService.set('common.warehouse', `Warehouse`, 'en')
    this.translateService.set('common.order', `Order`, 'en')
    this.translateService.set('common.Email', `Email`, 'en')
    this.translateService.set('common.from', `From`, 'en')
    this.translateService.set('common.to', `To`, 'en')
    this.translateService.set('common.wash', `Wash Date`, 'en')
    this.translateService.set('common.repair-date', `Repair Date`, 'en')
    this.translateService.set('common.repair-name', `Repair`, 'en')
    this.translateService.set('common.repair-number', `Repair Number`, 'en')
    this.translateService.set('common.search', `Search`, 'en')
    this.translateService.set('common.warehouse-location', `Location`, 'en')
    this.translateService.set('common.warehouse-locationslot', `Location Slot`, 'en')
    this.translateService.set(
      'common.storage-location',
      `Storage Location`,
      'en',
    )
    this.translateService.set('common.wash-number', `Wash No`, 'en')
    this.translateService.set(
      'common.actual-prod-date',
      `Actual Prod Date`,
      'en',
    )

    this.translateService.set('menu.dashboard', `Dashboard`, 'en')
    // Menu Master
    this.translateService.set('menu.masters', `Master`, 'en')
    // Sub Menu Master
    this.translateService.set('menu.master.lsp', `LSP`, 'en')
    this.translateService.set(
      'menu.master.movement-type',
      `Movement Type`,
      'en',
    )
    this.translateService.set(
      'menu.master.warehouse-group',
      `Warehouse Group`,
      'en',
    )
    this.translateService.set('menu.master.warehouse', `Warehouse`, 'en')
    this.translateService.set(
      'menu.master.product-group',
      `Product Group`,
      'en',
    )
    this.translateService.set('menu.master.uom', `UOM`, 'en')
    this.translateService.set('menu.master.product', `Product`, 'en')
    this.translateService.set(
      'menu.master.business-partner-group',
      `Business Partner Group`,
      'en',
    )
    this.translateService.set('menu.master.currency', `Currency`, 'en')
    this.translateService.set('menu.master.department', `Department`, 'en')
    this.translateService.set(
      'menu.master.relationship-type',
      `Relationship Type`,
      'en',
    )
    this.translateService.set(
      'menu.master.business-partner',
      `Business Partner`,
      'en',
    )
    this.translateService.set('menu.master.machine', `Machine`, 'en')
    this.translateService.set('menu.master.vehicle', `Vehicle`, 'en')
    this.translateService.set('menu.master.route', `Route`, 'en')
    this.translateService.set('menu.master.reasoncode', `Reason Code`, 'en')
    this.translateService.set(
      'menu.master.productstatus',
      `Product Status`,
      'en',
    )
    this.translateService.set('menu.master.producttype', `Product Type`, 'en')
    this.translateService.set(
      'menu.master.local-resource',
      `Local Resource`,
      'en',
    )
    this.translateService.set('menu.master.state', `State`, 'en')
    this.translateService.set('menu.master.country', `Country`, 'en')
    this.translateService.set('menu.master.employee', `Employee`, 'en')

    // Menu Purchase
    this.translateService.set('menu.purchase', `Purchase`, 'en')
    //Sub Menu Purchase
    this.translateService.set(
      'menu.purchase.purchase-requisition',
      `Purchase Requisition`,
      'en',
    )
    this.translateService.set(
      'menu.purchase.request-for-quotation',
      `Request for Quotation`,
      'en',
    )
    this.translateService.set(
      'menu.purchase.vendor-offer',
      `Vendor Offer`,
      'en',
    )
    this.translateService.set(
      'menu.purchase.comparative-offers',
      `Comparative Offers`,
      'en',
    )
    this.translateService.set(
      'menu.purchase.purchase-order',
      `Purchase Order`,
      'en',
    )
    this.translateService.set('menu.purchase.stock-level', `Stock Level`, 'en')

    //Menu Logistic
    this.translateService.set('menu.logistic', `Logistic`, 'en')
    //Sub Menu
    this.translateService.set('menu.logistic.order', `Order`, 'en')
    this.translateService.set(
      'menu.logistic.collection-order',
      `Collection Order`,
      'en',
    )
    this.translateService.set(
      'menu.logistic.mulcollection-order',
      `Multi Collection Order`,
      'en',
    )
    this.translateService.set(
      'menu.logistic.mulord-order',
      `Multi Order`,
      'en',
    )
    this.translateService.set(
      'menu.logistic.call-off-qty-order',
      `Call Off Qty Order`,
      'en',
    )
    this.translateService.set(
      'menu.logistic.dispatch-plan',
      `Dispatch Plan`,
      'en',
    )
    this.translateService.set(
      'menu.logistic.shipment-order',
      `Shipment Order`,
      'en',
    )

    //Menu CAAM
    this.translateService.set('menu.caam', `CAMM`, 'en')
    //Sub Menu CAAM
    this.translateService.set(
      'menu.caam.stock-and-movement',
      `Stock & Movement`,
      'en',
    )
    this.translateService.set(
      'menu.caam.exchange-pallet',
      `Exchange Pallet`,
      'en',
    )
    this.translateService.set(
      'menu.caam.pricing-and-condition',
      `Pricing & Condition`,
      'en',
    )
    this.translateService.set(
      'menu.caam.inbound-movement',
      `Inbound Movement`,
      'en',
    )
    this.translateService.set(
      'menu.caam.invoice-account',
      `Invoice Account`,
      'en',
    )

    //Menu Warehouse
    this.translateService.set('menu.warehouse', `Warehouse`, 'en')
    //Sub Meni Warehouse
    // this.translateService.set('menu.warehouse.shock', `Shock`, 'en');
    this.translateService.set('menu.warehouse.dashboard', `Dashboard`, 'en')
    this.translateService.set(
      'menu.warehouse.opening-stock-sheet',
      `Stock Sheet`,
      'en',
    )
    this.translateService.set(
      'menu.warehouse.goods-receive',
      `Goods Receive`,
      'en',
    )
    this.translateService.set('menu.warehouse.goods-issue', `Goods Issue`, 'en')
    this.translateService.set(
      'menu.warehouse.exchange-issue',
      `Exchange Issue`,
      'en',
    )
    this.translateService.set(
      'menu.warehouse.return-movement',
      `Return Movement`,
      'en',
    )
    this.translateService.set(
      'menu.warehouse.opening-stock',
      `Opening Stock`,
      'en',
    )
    this.translateService.set(
      'menu.warehouse.opening-stock-correction',
      `Opening Stock Correction`,
      'en',
    )
    this.translateService.set(
      'menu.warehouse.inventory',
      `Opening/Closing Stock`,
      'en',
    )

    //Menu Production
    this.translateService.set('menu.production', `production`, 'en')
    this.translateService.set(
      'menu.production.production-forcast',
      `Forecast`,
      'en',
    )
    this.translateService.set('menu.production.planning', `Planning`, 'en')
    this.translateService.set('menu.production.wash', `Wash`, 'en')
    this.translateService.set('menu.production.repair', `Repair`, 'en')
    this.translateService.set('menu.production.sorting', `Sorting`, 'en')
    this.translateService.set('menu.production.inspection', `Inspection`, 'en')

    this.translateService.set('menu.logout', `Logout`, 'en')

    // User Info

    this.translateService.set(
      'common.user.caption.insert',
      `Business Partner User`,
      'en',
    )
    this.translateService.set('common.user-usertype', `User Type`, 'en')
    this.translateService.set('common.user-bpName', `Business Partner`, 'en')
    this.translateService.set('common.user-username', `User Name`, 'en')
    this.translateService.set('common.user-password', `Password`, 'en')
    this.translateService.set(
      'common.user-confirmpassword',
      `Confirm Password`,
      'en',
    )
    this.translateService.set(
      'common.user-secretquestion',
      `Secret Question`,
      'en',
    )
    this.translateService.set('common.user-secretanswer', `Secret Answer`, 'en')
    this.translateService.set('common.user-phoneno1', `Phone No 1`, 'en')
    this.translateService.set('common.user-phoneno2', `Phone No 2`, 'en')
    this.translateService.set('common.user-fax', `Fax`, 'en')
    this.translateService.set('common.user-email', `Email`, 'en')
    this.translateService.set('common.user-language', `Language`, 'en')
    this.translateService.set('common.user-acti', `Active`, 'en')
    this.translateService.set(
      'common.user-confirmPwdRequired',
      `Confirm Pasword Required`,
      'en',
    )
    this.translateService.set(
      'common.user-confirmPwdNotMatch',
      `Confirm Pasword Not Match`,
      'en',
    )

    //all list

    this.translateService.set(
      'common.edit-not-allowed',
      `Edit is not allowed`,
      'en',
    )
    this.translateService.set(
      'common.cancel-not-allowed',
      `Cancel is not allowed`,
      'en',
    )
    this.translateService.set(
      'common.split-not-allowed',
      `Split is not allowed`,
      'en',
    )

    //new
    this.translateService.set('common.notfound', `Not Found!`, 'en')
    this.translateService.set('common.addnew', `Add New ?`, 'en')
    this.translateService.set('common.product.prodname', `Prod Name`, 'en')
    this.translateService.set(
      'common.product.productcode',
      `Customer Product Code`,
      'en',
    )
    this.translateService.set(
      'common.product.productgroup',
      `Product Group`,
      'en',
    )
    this.translateService.set(
      'common.product.typeofproduct',
      `Type of Product`,
      'en',
    )
    this.translateService.set(
      'common.product.pricingtype',
      `Pricing Type`,
      'en',
    )
    this.translateService.set('common.product.ownertype', `Owner Type`, 'en')
    this.translateService.set('common.product.uom', `Uom`, 'en')
    this.translateService.set('common.split', `Split`, 'en')
    this.translateService.set('common.required-date', `Date`, 'en')
    this.translateService.set('common.rfu', `RFU`, 'en')
    this.translateService.set('common.tbw', `TBW`, 'en')
    this.translateService.set('common.tbr', `TBR`, 'en')
    this.translateService.set('common.scrap', `SCRAP`, 'en')
    this.translateService.set('common.tbhw', `TBHW`, 'en')
    this.translateService.set('common.timefrom', `Time From`, 'en')
    this.translateService.set('common.timeto', `Time To`, 'en')
    this.translateService.set(
      'common.balancequantity',
      `Balance Quantity`,
      'en',
    )
    this.translateService.set('common.timedifference', `Difference`, 'en')

    //console.log("Language Service Init");

    this.translateService.onLangChange.subscribe((data: LangChangeEvent) => {
      if (data.lang != 'en') {
        const browserCultureLang = this.translateService.getBrowserCultureLang()
        this.getTranslationByPhrase('common').subscribe((resp) => {
          resp.forEach((item) => {
            this.translateService.set(
              item.resourceName,
              item.resourceValue,
              browserCultureLang,
            )
          })
        })
      }
    })
  }

  private getTranslationByPhrase(phrase: string) {
    const url = `/api/LocaleResource/GetPhrasewiseResource/${phrase}`
    return this.http.get<Array<TranslateModel>>(url)
  }

  loadLocalResource(phrase: string) {
    const loaded = new Subject<number>()
    const browserCultureLang = this.translateService.getBrowserCultureLang()
    //console.log('Browser Cult Lang : ', browserCultureLang);
    const lang = this.translateService.getBrowserLang()
    //console.log('Current Lang : ', lang)
    //if (lang != 'en') {
    if (true) {
      this.getTranslationByPhrase(phrase).subscribe(
        (resp) => {
          this.translateService.use(browserCultureLang)
          //console.log(resp);
          resp.forEach((item) => {
            this.translateService.set(
              item.resourceName,
              item.resourceValue,
              browserCultureLang,
            )
          })
          loaded.next(0)
          loaded.complete()
        },
        (err) => {
          loaded.next(0)
          loaded.complete()
          this.translateService.use('en')
        },
      )
    } else {
      this.translateService.use('en')
      return of(0)
    }
    return loaded
  }
}

interface TranslateModel {
  localeStringResourceId: number
  resourceName: string
  resourceValue: string
}
