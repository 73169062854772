import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }


  getUserNotification() {
    const url = "/api/Notification/GetNotificationByCompany";
    return this.http.get<Array<UserNotification>>(url);
  }

  searchNotification(searchTerm: string) {
    const url = `/api/Notification/GetNotificationByPhrase/${searchTerm}`;
    return this.http.get<SearchNotification>(url);
  }
}

export interface SearchNotification {
  notification: Array<UserNotification>,
  productresult: Array<ProductResult>,
  busiesspartner: Array<BusinessPartnerResult>,
}
export interface BusinessPartnerResult {
  bpid: number;
  bpname: string;
  searchtype: number;
}
export interface ProductResult {
  productCd: string;
  productDetail: string;
  productId: number;
  searchtype: number;
}
export interface UserNotification {
  bpid: number;
  bpname: string;
  message: string;
  refDocId: number;
  refDoc?: string;
  refDocTypeId: number;
  refDocType: string;
  transDatetime: Date;
  refDocNumber: string;
}