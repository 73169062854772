<div class="container-fluid">
    <div class="col-12 mt-2">
        <div>
            <pp-loading
              [show]="isLoading"
              [message]="'common.loading' | translate"
            ></pp-loading>
          </div>
    </div>
    <div class="col-12">
    <div class="row">
        <div class="col-md-3">
            <a class="navbar-brand" >
                <img src="/assets/logo/brand.png" alt="Brand Logo" title="PackPlan">
            </a>
        </div>
        <div class="col-6">
            <span  class="user-info" >
                {{ currentUser.userName }}
                <b class="caret"></b>
              </span> &nbsp;&nbsp;
            <i class="fa fa-clock"></i>
            {{ currentDate | amDateFormat: "DD MMM. YYYY ; HH:mm:ss" }} Hrs &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button color="accent"  (click)="BackButton()" class="btn-pp" *ngIf="isbackvisible"  style="background-color:green;" mat-raised-button>  {{ "menu.back" | translate }}</button>
        </div>
       
        <div class="row">
           
            <div class="col-md-6 text-right">
                <div class="text-right">
                  <!--   <button (click)="Dashboard()" color="primary" class="btn-pp" mat-raised-button>Dashboard</button>-->
                     <mat-button-toggle-group class="btn-pp">
                        <mat-button-toggle class="split-button-1" style="background-color: blue; color: white;" (click)="Dashboard()" >Dashboard</mat-button-toggle>
                        <mat-button-toggle class="split-button-1 drop-down-button" style="background-color: blue;" [matMenuTriggerFor]="dropdownMenuOne">
                          <mat-icon style="background-color: blue; color: white; ">arrow_drop_down</mat-icon>
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                      
                      <mat-menu #dropdownMenuOne="matMenu" xPosition="before">
                        <button mat-menu-item routerLink="/warehouse/production/goods-receipt" > {{ "menu.warehouse.goods-receive" | translate }}</button>
                        <button mat-menu-item routerLink="/warehouse/production/planninglist">{{ "menu.production.planning" | translate }}</button>
                        <button mat-menu-item routerLink="/warehouse/production/inspection">{{ "menu.production.inspection" | translate }}</button>
        
                        <button mat-menu-item routerLink="/warehouse/production/sorting"> {{ "menu.production.sorting" | translate }}</button>
                        <button mat-menu-item routerLink="/warehouse/production/Wash">{{ "menu.production.wash" | translate }}</button>
                        <button mat-menu-item routerLink="/warehouse/production/Repair">{{ "menu.production.repair" | translate }}</button>
        
                        <button mat-menu-item routerLink="/warehouse/production/goods-issue">{{ "menu.warehouse.goods-issue" | translate }}</button>
                        <button mat-menu-item routerLink="/warehouse/production/Stocksheet">{{ "menu.warehouse.opening-stock-sheet" | translate }}</button>
                      </mat-menu> 
                </div>
               
            </div>
            <div class="col-md-6 text-right">
                <div class="text-right">
                
                    <button color="accent" (click)="logout()" class="btn-pp" mat-raised-button>  {{ "menu.logout" | translate }}</button>
                </div>
               
            </div>
        </div>
    </div>
</div>
    
</div>
<hr />
<router-outlet></router-outlet>