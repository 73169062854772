import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/module/material/material.module';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { RouterModule } from '@angular/router';
import { PullDispathOrderDialogComponent } from './shared/pull-dispath-order-dialog/pull-dispath-order-dialog.component';
//import { StockCorrectionListComponent } from './opening-stock-correction/opening-stock-correction-list/stock-correction-list.component';
//import { ProdslotsmanagemantComponent } from '../../prodslotsmanagemant/prodslotsmanagemant.component';
import { TransactionCancelDialogComponent} from '../shared/transaction-cancel-dialog/transaction-cancel-dialog.component'



@NgModule({
  declarations: [PullDispathOrderDialogComponent,TransactionCancelDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DirectiveModule,
    PipeModule
  ],
  exports: [
    PullDispathOrderDialogComponent,
    PullDispathOrderDialogComponent,
    ///StockCorrectionListComponent,
  ]
})
export class WarehouseModule { }
