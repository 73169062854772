import { Component, OnInit, OnDestroy } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user/user';
import { interval, merge, Observable, Subject, Subscription } from 'rxjs';
import { Location } from '@angular/common';

import {
  NotificationService,
  UserNotification,
} from './../../../services/notification/notification.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HeaderComponent, QuickAction } from '../header/header.component';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, filter, map } from 'rxjs/operators';

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
// export const ROUTES: RouteInfo[] = [
//   {
//     path: '/',
//     title: 'Dashboard',
//     type: 'link',
//     icontype: 'dashboard'
//   },
//   {
//     path: '/master',
//     title: 'Masters',
//     type: 'sub',
//     icontype: 'content_paste',
//     collapse: 'master',
//     children: [
//       { path: 'lsp', title: 'LSP', ab: 'LS' },
//       { path: 'movement-type', title: 'Movement Type', ab: 'MT' },
//       { path: 'warehouse-group', title: 'Warehouse Group', ab: 'WG' },
//       { path: 'warehouse', title: 'Warehouse', ab: 'WH' },
//       { path: 'product-group', title: 'Product Group', ab: 'PG' },
//       { path: 'uom', title: 'UOM', ab: 'UO' },
//       { path: 'product', title: 'Product', ab: 'PR' },
//       { path: 'business-partner-group', title: 'Business Partner Group', ab: 'BG' },
//       { path: 'currency', title: 'Currency', ab: 'CR' },
//       { path: 'department', title: 'Department', ab: 'DP' },
//       { path: 'relationship-type', title: 'Relationship Type', ab: 'RT' },
//       { path: 'business-partner', title: 'Business Partner', ab: 'BP' },
//       { path: 'machine', title: 'Machine', ab: 'MH' },
//     ]
//   },
//   {
//     path: '/purchase',
//     title: 'Purchase',
//     type: 'sub',
//     icontype: 'apps',
//     collapse: 'components',
//     children: [
//       { path: 'requisition', title: 'Purchase Requisition', ab: 'REQ' },
//       { path: 'request-for-quotation', title: 'Request for Quotation', ab: 'RFQ' },
//       { path: 'vendor-offer', title: 'Vendor Offer', ab: 'VEO' },
//       { path: 'comparative-offers', title: 'Comparative Offers', ab: 'COS' },
//       { path: 'order', title: 'Purchase Order', ab: 'POR' },
//       // { path: 'notifications', title: 'Notifications', ab: 'N' },
//       // { path: 'icons', title: 'Icons', ab: 'I' },
//       // { path: 'typography', title: 'Typography', ab: 'T' }
//     ]
//   },
//   {
//     path: '/logistics',
//     title: 'Logistics',
//     type: 'sub',
//     icontype: 'grid_on',
//     collapse: 'logistics',
//     children: [
//       { path: 'order', title: 'Order', ab: 'OR' },
//       { path: 'collection-order', title: 'Collection Order', ab: 'CO' },
//       { path: 'call-off-qty-order', title: 'Call Off Qty Order', ab: 'CQ' },
//       { path: 'shipment-order', title: 'Shipment Order', ab: 'SO' }
//     ]
//   },
//   {
//     path: '/',
//     title: 'CAAM',
//     type: 'sub',
//     icontype: 'place',
//     collapse: 'caam',
//     children: [
//       { path: 'stock-and-movement', title: 'Stock & Movement', ab: 'SM' },
//       { path: 'exchange-Pallet', title: 'Exchange Pallet', ab: 'EP' },
//       { path: 'pricing-and-condition', title: 'Pricing & Condition', ab: 'PC' },
//       { path: 'inbound-movement', title: 'Inbound Movement', ab: 'IM' },
//       { path: 'invoice-account', title: 'Invoice Account', ab: 'IA' }
//     ]
//   },
//   {
//     path: '/warehouse',
//     title: 'Warehouse',
//     type: 'sub',
//     icontype: 'widgets',
//     collapse: 'warehouse',
//     children: [
//       { path: 'shock', title: 'Shock', ab: 'S' },
//       { path: 'goods-receive', title: 'Goods Receive', ab: 'GR' },
//       { path: 'goods-issue', title: 'Goods Issue', ab: 'GI' },
//       { path: 'exchange-issue', title: 'Exchange Issue', ab: 'EI' },
//       { path: 'return-movement', title: 'Return Movement', ab: 'RM' }
//     ]
//   },
// {
//   path: '/charts',
//   title: 'Charts',
//   type: 'link',
//   icontype: 'timeline'

// },
// {
//   path: '/calendar',
//   title: 'Calendar',
//   type: 'link',
//   icontype: 'date_range'
// },
// {
//   path: '/pages',
//   title: 'Pages',
//   type: 'sub',
//   icontype: 'image',
//   collapse: 'pages',
//   children: [
//     { path: 'pricing', title: 'Pricing', ab: 'P' },
//     { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
//     { path: 'login', title: 'Login Page', ab: 'LP' },
//     { path: 'register', title: 'Register Page', ab: 'RP' },
//     { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
//     { path: 'user', title: 'User Page', ab: 'UP' }
//   ]
// }
//];

@Component({
  selector: 'pp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  currentDate: Date;
  currentUser: User;
  subscription: Subscription;
  notification: Array<UserNotification> = [];
  minimizedSidebar: boolean = false;
  quickActionForm: FormGroup;

  quickActionList: Array<QuickAction> = [];
  quickActionResult: Observable<Array<QuickAction>>;
  apiSearch: Subject<Array<QuickAction>>;
  location: Location;

  constructor(
    location: Location,
    private authService: AuthService,
    private notificationService: NotificationService,
    private angularFireMessaging: AngularFireMessaging,
    private communicationService: CommunicationService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.location = location;
    this.apiSearch = new Subject<Array<QuickAction>>();
  }

  public menuItems: any[];
  private listTitles: any[];

  ps: PerfectScrollbar;
  minimizeSidebar() {
    this.communicationService.toggleSidebar.next(null);
    this.minimizedSidebar = !this.minimizedSidebar;
    console.log(this.communicationService, this.minimizeSidebar);
    if (this.minimizedSidebar) {
      document.getElementById('list').style.display = 'none';
    } else if (!this.minimizedSidebar) {
      document.getElementById('list').style.display = 'block';
    }
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private _quickActionFilter(value: string): Array<QuickAction> {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.quickActionList.filter(
        (option) =>
          option.ab?.toLowerCase().includes(filterValue) ||
          option.text.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }

  searchNotification(searchTerm: string) {
    console.log('apiSearch q', searchTerm, this.menuItems);

    if (searchTerm && searchTerm.length > 2) {
      this.notificationService
        .searchNotification(searchTerm)
        .subscribe((searchResult) => {
          let quickAction: Array<QuickAction> =
            this._quickActionFilter(searchTerm);

          searchResult.notification.forEach((item) => {
            switch (
              item.refDocTypeId
              //purchase requisition
              // case 91:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/requisition/edit/${item.refDocNumber}`,
              //     type: 'REQ',
              //   });
              //   break;
              // //Request for Quotation
              // case 92:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/request-for-quotation/edit/${item.refDocNumber}`,
              //     type: 'RFQ',
              //   });
              //   break;
              // //Vendor Quotation
              // case 93:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/vendor-offer/edit/${item.refDocId}`,
              //     type: 'VEO',
              //   });
              //   break;
              //Purchase Order
              // case 94:
              //   quickAction.push({
              //     text: item.message,
              //     link: `/purchase/order/edit/${item.refDocNumber}`,
              //     type: 'POR',
              //   });
              //   break;
            ) {
            }
          });

          // searchResult.productresult.forEach((item) => {
          //   quickAction.push({
          //     text: item.productDetail,
          //     link: `/master/product/edit/${item.productId}`,
          //     type: 'PRODUCT',
          //   });
          // });

          // searchResult.busiesspartner.forEach((item) => {
          //   quickAction.push({ text: item.bpname, link: ``, type: 'BP' });
          // });

          this.apiSearch.next(quickAction);
        });
    }
  }

  ngOnInit() {
    const secondsCounter = interval(1000);
    this.subscription = secondsCounter.subscribe((n) => {
      this.currentDate = new Date();
    });
    this.quickActionForm = this.fb.group({
      search: [''],
    });
    this.currentUser = this.authService.currentUser();
console.log('this.currentUser',this.currentUser);
if(this.currentUser.whid!=null)
{
  console.log('depotusder')

  //this.menuItems = this.communicationService.getDepotMenuList();
 // console.log('this.menuItems',this.menuItems)
 // const localSearch = this.quickActionForm.get('search').valueChanges.pipe(
 //   debounceTime(100),
 //   map((value) => {
  //   this.searchNotification(value);
   //   return this._quickActionFilter(value);
   // })
  //);

 // this.quickActionResult = merge(localSearch, this.apiSearch);

  //this.listTitles = this.communicationService.getDepotMenuList();

 // this.communicationService.getMenuList().forEach((item) => {
   
   // if (item.type == 'sub') {
   //   item.children.forEach((child) => {
   ////     this.quickActionList.push({
       //   link: `${item.path}/${child.path}`,
      //    text: child.title,
      //    type: child.type,
      //    ab: child.ab,
      //  });
      //});
    //}
  //});
}
else
{
    this.menuItems = this.communicationService.getMenuList();

    const localSearch = this.quickActionForm.get('search').valueChanges.pipe(
      debounceTime(100),
      map((value) => {
        this.searchNotification(value);
        return this._quickActionFilter(value);
      })
    );

    this.quickActionResult = merge(localSearch, this.apiSearch);

    this.listTitles = this.communicationService.getMenuList();

    this.communicationService.getMenuList().forEach((item) => {
      // if (item.type == 'link') {
      //   this.quickActionList.push({
      //     icon: item.icontype,
      //     link: item.path,
      //     text: item.title,
      //     type: '',
      //   });
      // } else
      if (item.type == 'sub') {
        item.children.forEach((child) => {
          this.quickActionList.push({
            link: `${item.path}/${child.path}`,
            text: child.title,
            type: child.type,
            ab: child.ab,
          });
        });
      }
    });
  }
    const elemSidebar = <HTMLElement>(
      document.querySelector('.sidebar .sidebar-wrapper')
    );
    this.ps = new PerfectScrollbar(elemSidebar);
    this.currentUser = this.authService.currentUser();

    this.angularFireMessaging.messages.subscribe((data) => {
      console.log('Push Notification received ', data);
      this.notificationService
        .getUserNotification()
        .subscribe((notifications) => {
          this.notification = notifications;
        });
    });

    this.notificationService
      .getUserNotification()
      .subscribe((notifications) => {
        this.notification = notifications;
      });
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  onQukckAction(path: string) {
    this.quickActionForm.get('search').setValue(null);
    this.router.navigate([path]);
  }

  logout() {
    this.authService.logout('logout');
  }
  // search(value: string) {
  //   console.log(this.menuItems);
  //   this.menuItems.filter((e) => {
  //     console.log(e);
  //   });
  // }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (let i = 0; i < this.listTitles.length; i++) {
      // if (
      //   this.listTitles[i].type === 'link' &&
      //   this.listTitles[i].path === titlee
      // ) {
      //   return this.listTitles[i].title;
      // } else
      if (this.listTitles[i].type === 'sub') {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle =
            this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
          // console.log(subtitle)
          // console.log(titlee)
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return 'Dashboard';
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }
}
