import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
  selector: 'pp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit {
  uploadBtnText: string;
  @ViewChild('file', { static: false }) elementRef: ElementRef;
  element: HTMLInputElement;
  @Output('fileSelected') fileSelected: EventEmitter<string | Array<string> | ArrayBuffer | Array<ArrayBuffer>>;

  @Input('multiple') multiple: any;

  constructor(
    private translatePipe: TranslatePipe
  ) {
    this.fileSelected = new EventEmitter();
    this.uploadBtnText = this.translatePipe.transform('common.upload-file');
  }

  ngAfterViewInit(): void {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {

  }

  async onFileSelected(event: Event) {

    if (this.element.files.length > 1) {
      this.uploadBtnText = `${this.element.files.length} ${this.translatePipe.transform('common.files-selected')}`;
      let files = [];
      for (let i = 0; i < this.element.files.length; i++) {
        files.push(await this.readFile(this.element.files[i]));
      }
      this.fileSelected.emit(files);
    } else if (this.element.files.length == 1) {
      this.uploadBtnText = `${this.element.files[0].name}`;
      this.fileSelected.emit(await this.readFile(this.element.files[0]));
    } else if (this.element.files.length == 0) {
      this.uploadBtnText = this.translatePipe.transform('common.upload-file');
      this.fileSelected.emit(null);
    }
  }

  onUploadButton() {
    if (this.element.files.length > 0) {
      this.element.value = null;
      this.uploadBtnText = this.translatePipe.transform('common.upload-file');
    } else {
      this.element.click();
    }
  }
  readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  };
}
