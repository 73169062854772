export const settings = {
  appVersion:'4.0.0.0.1',
  currentUser: "CurrentUser",
  corporateId:"CorporateId",
  hasStore:"hasStore",
  userEmail: "Email",
  userToken: "Token",
  usertypeid:"UserType",
  lastSavedOn: "-time",
  stickyFormCacheHour: 1,
  httpErrorMessage: {
    remoteValidationFailed: "failed to validate",
    httpFailure: "Unable to reach the server right now",
    internalServerError: "Internal Server Error",
    badRequest:"Ops... something went wrong !"
  },
  defaultAvatarUrl: "dist/img/img1.jpg",
  userRole: {
    admin: "Admin",
    superAdmin: "SuperAdmin",
    customer: "Customer"
  }
}
