import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot } from '@angular/router'
import { ActivatedRouteSnapshot } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

import { LanguageService } from '../../services/language/language.service'

@Injectable()
export class ProductionModuleLanguageResolver implements Resolve<number> {
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<number> {
    this.translateService.set(
      'prod.production-planing-caption',
      `Production Planing`,
      'en',
    )
    this.translateService.set('prod.production-plan', `Production Plan`, 'en')
    this.translateService.set('prod.order-no', `Order No`, 'en')
    this.translateService.set('prod.bp-name', `BP Name`, 'en')
    this.translateService.set('prod.product-name', `Product Name`, 'en')
    this.translateService.set('prod.no-of-issue', `No of Issue`, 'en')
    this.translateService.set('prod.no-of-receipts', `No of receipts`, 'en')
    this.translateService.set('prod.non-rfu-qty', `Non Rfu Qty`, 'en')
    this.translateService.set('prod.rfu-qty', `Rfu Qty`, 'en')
    this.translateService.set('prod.production-order', `Production Order`, 'en')
    this.translateService.set('prod.production-date', `Production Date`, 'en')
    this.translateService.set('prod.production-type', `Production Type`, 'en')
    this.translateService.set('prod.machine', `Machine`, 'en')
    this.translateService.set('prod.employee', `Employee`, 'en')
    this.translateService.set('prod.machine-capacity', `Machine Capacity`, 'en')
    this.translateService.set('prod.rfu', `RFU`, 'en')
    this.translateService.set('prod.slotno', `SlotNo`, 'en')
    this.translateService.set('prod.capacity', `Capacity`, 'en')
    this.translateService.set('prod.slotstock', `SlotStock`, 'en')
    this.translateService.set('prod.todayplanning', `Today Planning`, 'en')

 
    this.translateService.set(
      'prod.production-order-qty',
      `PORD Qty`,
      'en',
    )
    this.translateService.set('prod.awi', `AWI`, 'en')
    this.translateService.set('prod.tbw', `TBW`, 'en')
    this.translateService.set('prod.tbhw', `TBHW`, 'en')
    this.translateService.set('prod.tbr', `TBR`, 'en')
    this.translateService.set('prod.system-qty', `System Qty`, 'en')
    this.translateService.set('prod.user-qty', `User Qty`, 'en')
    this.translateService.set(
      'prod.save-production-order',
      `Save Production Order`,
      'en',
    )
    this.translateService.set(
      'prod.production-per-hour',
      `Production Per Hour`,
      'en',
    )
    this.translateService.set('prod.planing', `Planing`, 'en')
    this.translateService.set(
      'prod.production-order-no',
      `Production Order No`,
      'en',
    )
    this.translateService.set('prod.order-date', `Order Date`, 'en')
    this.translateService.set('prod.warehouse-name', `Warehouse Name`, 'en')
    this.translateService.set('prod.machine-name', `Machine Name`, 'en')
    this.translateService.set('prod.employee-name', `Employee`, 'en')
    this.translateService.set('prod.status-name', `Status`, 'en')
    this.translateService.set('prod.total-cord', `Total CORD`, 'en')
    this.translateService.set('prod.available-qty', `Available Qty`, 'en')
    this.translateService.set('prod.last-cord', `Last CORD`, 'en')
    this.translateService.set(
      'prod.despatch-order-no',
      `Despatch Order No`,
      'en',
    )
    this.translateService.set('prod.product-cd', `Product Code`, 'en')
    this.translateService.set('prod.product-name', `Product Name`, 'en')
    this.translateService.set('prod.order-qty', `Product Qty`, 'en')
    this.translateService.set('prod.available-qty', `Available Qty`, 'en')
    this.translateService.set('prod.quantity', `Quantity`, 'en')
    this.translateService.set('prod.production-wash-caption', `Wash`, 'en')
    this.translateService.set(
      'prod.production-wash-list-caption',
      `Wash List`,
      'en',
    )
    this.translateService.set('prod.execution-date', `Date`, 'en')
    this.translateService.set('prod.wash-number', `Wash No`, 'en')

    return this.languageService.loadLocalResource('prod')
  }
}
