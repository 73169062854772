import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  productDropdown(): Observable<Array<ProductDropdown>> {
    const url = '/api/Product/GetAllForSelection';
    return this.http.get<Array<ProductDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductDropdown = {
            productCd: item.productCd,
            prodName: item.prodName,
          };
          return data;
        });
      })
    );
  }

  productByServiceTypeDropdown(
    servicetypeId: number
  ): Observable<Array<ProductDropdown>> {
    const url = `/api/Product/GetProductByServiceTypeId/${servicetypeId}`;
    return this.http.get<Array<ProductDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductDropdown = {
            productCd: item.productCd,
            prodName: item.prodName,
          };
          return data;
        });
      })
    );
  }

  GetProductsByBusinessTypeBP(
    businessTypeId: number,
    businessPartnerId: number
  ): Observable<Array<ProductDropdown>> {
    const url = `/api/Product/GetProductsByBusinessTypeBP/${businessTypeId}/${businessPartnerId}`;
    return this.http.get<Array<ProductDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductDropdown = {
            productCd: item.productCd,
            prodName: item.prodName,
          };
          return data;
        });
      })
    );
  }

  productStatusDropdown(): Observable<Array<ProductStatusDropdown>> {
    const url = '/api/ProductStatus/GetAllForSelection/0';

    return this.http.get<Array<ProductStatusDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductStatusDropdown = {
            statusCd: item.statusCd,
            statusD: item.statusD,
          };
          return data;
        });
      })
    );
  }

  getProductStatus(
    documentType: number
  ): Observable<Array<ProductStatusDropdown>> {
    const url = `/api/ProductStatus/GetAllForSelection/${documentType}`;
    return this.http.get<Array<ProductStatusDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductStatusDropdown = {
            statusCd: item.statusCd,
            statusD: item.statusD,
          };
          return data;
        });
      })
    );
  }

  productCategoryDropdown(): Observable<Array<ProductCategoryDropdown>> {
    const url = '/api/ProductCategory/GetAllForSelection';
    return this.http.get<Array<ProductCategoryDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductCategoryDropdown = {
            categoryCd: item.categoryCd,
            categoryD: item.categoryD,
          };
          return data;
        });
      })
    );
  }

  productGroupDropdown(): Observable<Array<ProductGroupDropdown>> {
    const url = '/api/ProductGroup/GetAllForSelection';
    return this.http.get<Array<ProductGroupDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductGroupDropdown = {
            prodgroupid: item.prodgroupid,
            groupdesc: item.groupdesc,
          };
          return data;
        });
      })
    );
  }
  productTypeDropdown(
    probgrpid: number
  ): Observable<Array<ProductTypeDropdown>> {
    const url = '/api/ProductType/GetAllForSelection/' + probgrpid;
    return this.http.get<Array<ProductTypeDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductTypeDropdown = {
            prodtypeid: item.prodtypeid,
            typedesc: item.typedesc,
          };
          return data;
        });
      })
    );
  }
  productPricingTypeDropdown(): Observable<Array<ProductPricingTypeDropdown>> {
    const url = '/api/ProductPricingType/GetAllForSelection';
    return this.http.get<Array<ProductPricingTypeDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductPricingTypeDropdown = {
            pricingtypecd: item.pricingtypecd,
            pricingtyped: item.pricingtyped,
          };
          return data;
        });
      })
    );
  }

  ownerTypeDropdown(): Observable<Array<OwnerTypeDropdown>> {
    const url = '/api/CoreMaster/GetProductOwnerTypeForSelection';
    return this.http.get<Array<OwnerTypeDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: OwnerTypeDropdown = {
            ownerTypeCd: item.ownerTypeCd,
            description: item.description,
          };
          return data;
        });
      })
    );
  }

  createProduct(data: ProductCreateModel) {
    console.log(data);
    const url = '/api/Product/CreateProduct';
    return this.http.post<any>(url, data);
  }

  updateProduct(data: ProductUpdate) {
    console.log(data);
    const url = '/api/Product/UpdateProduct';
    return this.http.put<any>(url, data);
  }

  productCodePatternDropdown(): Observable<Array<ProductCodePatternDropdown>> {
    const url = '/api/ProductCode/GetAllForSelection';
    return this.http.get<Array<ProductCodePatternDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductCodePatternDropdown = {
            prodCodeId: item.prodCodeId,
            prodCodePreview: item.prodCodePreview,
          };
          return data;
        });
      })
    );
  }

  uomTypeDropdown(): Observable<Array<UomTypeDropdown>> {
    const url = '/api/CoreMaster/GetUOMForSelection';
    return this.http.get<Array<UomTypeDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: UomTypeDropdown = {
            uomcd: item.uomcd,
            uomdesc: item.uomdesc,
          };
          return data;
        });
      })
    );
  }

  getProductListGrid(): Observable<Array<ProductList>> {
    const url = '/api/Product/GetAll/10000/1';
    return this.http.get<Array<ProductList>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductList = {
            // $id: item.$id,
            prodCodeId: item.prodCodeId,
            prodgroupid: item.prodgroupid,
            prodtypeid: item.prodtypeid,
            productId: item.productId,
            productCd: item.productCd,
            productGroup: item.productGroup,
            prodType: item.prodType,
            prodName: item.prodName,
            custProdCd: item.custProdCd,
            productDetail: item.productDetail,
            uom: item.uom,
            prodLength: item.prodLength,
            prodWidth: item.prodWidth,
            prodHeight: item.prodHeight,
            prodGrossWeight: item.prodGrossWeight,
            prodWeight: item.prodWeight,
            belongsto: item.belongsto,
            itemtype: item.itemtype,
            backorderitem: item.backorderitem,
            singleunititem: item.singleunititem,
            masteraccd: item.masteraccd,
            isBom: item.isBom,
            hired: item.hired,
            extProductNo: item.extProductNo,
            extProductName: item.extProductName,
            latestProdCode: item.latestProdCode,
            ownerType: item.ownerType,
            ownerTypeDes: item.ownerTypeDes,
            pricingType: item.pricingType,
            pricingTypeDes: item.pricingTypeDes,
            active: item.active,
            visible: item.visible,
            uomcd: item.uomcd,
            qtyhired: item.qtyhired,
            loadmeter: item.loadmeter,
          };
          return data;
        });
      })
    );
  }

  getProductByProductId(productId: string) {
    const url = `/api/Product/GetProductById/${productId}`;
    return this.http.get<ProductUpdate>(url);
  }

  // getProductAttachments(productId: number) {

  //   const url = `/api/Product/GetProductAttachments/${productId}`;
  //   return this.http.get<ProductFile>(url);
  // }

  getProductAttachments(productId: number): Observable<Array<ProductFile>> {
    const url = `/api/Product/GetProductAttachments/${productId}`;
    return this.http.get<Array<ProductFile>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductFile = {
            // $id: item.$id,
            documentId: item.documentId,
            productId: item.productId,
            filePath: item.filePath,
          };
          return data;
        });
      })
    );
  }

  updateProductAttachments(data: updateProductFile) {
    console.log(data);
    const url = '/api/Product/AddAttachment';
    return this.http.post<any>(url, data);
  }

  deletProductAttachments(documentId: number) {
    console.log(documentId);
    const url = `/api/Product/DeleteAttachment/${documentId}`;
    return this.http.delete<any>(url);
  }

  productStatusByDocumentTypeDropdown(
    documentType: number
  ): Observable<Array<ProductStatusDropdown>> {
    const url = `/api/ProductStatus/GetAllForSelection/${documentType}`;
    return this.http.get<Array<ProductStatusDropdown>>(url).pipe(
      map((data) => {
        return data.map((item) => {
          const data: ProductStatusDropdown = {
            statusCd: item.statusCd,
            statusD: item.statusD,
          };
          return data;
        });
      })
    );
  }

  getProductByMachine(machineCd: number) {
    const url = `/api/Product/GetProductByMachine/${machineCd}`;
    return this.http.get<any>(url);
  }
}

export interface ProductDropdown {
  productCd: string;
  prodName: string;
}
export interface ProductStatusDropdown {
  statusCd: number;
  statusD: string;
}

export interface ProductCategoryDropdown {
  categoryCd: number;
  categoryD: string;
}

export interface ProductGroupDropdown {
  // $id: string,
  prodgroupid: number;
  //groupsdesc: string,
  groupdesc: string;
}

export interface ProductTypeDropdown {
  // $id: string,
  prodtypeid: number;
  //typesdesc: string,
  typedesc: string;
  //prodgroupid: number
}

export interface ProductPricingTypeDropdown {
  // $id: string,
  pricingtypecd: number;
  //pricingtypesd: string,
  pricingtyped: string;
}

export interface OwnerTypeDropdown {
  // $id: string,
  ownerTypeCd: number;
  description: string;
}

export interface ProductCodePatternDropdown {
  // $id: string,
  prodCodeId: number;
  prodCodePreview: string;
}

export interface UomTypeDropdown {
  // $id: string,
  uomcd: number;
  uomdesc: string;
}

export interface ProductCreateModel {
  prodgroupid: number;
  productGroupName: string;
  prodtypeid: number;
  productTypeName: string;
  prodName: string;
  custProdCd: string;
  productDetail: string;
  uomcd: number;
  prodLength: number;
  prodWidth: number;
  prodHeight: number;
  prodGrossWeight: number;
  prodWeight: number;
  color: string;
  belongsto: string;
  itemtype: string;
  backorderitem: number;
  singleunititem: number;
  isBom: number;
  hired: boolean;
  qtyhired: number;
  loadmeter: number;
  ownerType: number;
  pricingType: number;
  extProductNo: string;
  extProductName: string;
  reOrderLevelQty: number;
  minStockQty: number;
  maxStockQty: number;
  minOrderQty: number;
  maxOrderQty: number;
  avgLeadTime: number;
  attachFiles: Array<string>;
  negativeStock: number;
  trading: number;
  service: number;
  productCd: string;
  active: number;
  serviceTypeId: number;
}

export interface ProductList {
  // $id: string,
  prodCodeId: number;
  prodgroupid: number;
  prodtypeid: number;
  productId: number;
  productCd: string;
  productGroup: string;
  prodType: string;
  prodName: string;
  custProdCd: string;
  productDetail: string;
  uom: string;
  prodLength: number;
  prodWidth: number;
  prodHeight: number;
  prodGrossWeight: number;
  prodWeight: number;
  belongsto: string;
  itemtype: string;
  backorderitem: number;
  singleunititem: number;
  masteraccd: string;
  isBom: number;
  hired: boolean;
  extProductNo: string;
  extProductName: string;
  latestProdCode: number;
  ownerType: number;
  ownerTypeDes: string;
  pricingType: number;
  pricingTypeDes: string;
  active: number;
  visible: boolean;
  uomcd: number;
  qtyhired: number;
  loadmeter: number;
}

export interface Product {
  id?: number;
  productId?: string;
  productCd?: string;
  productGroup: string;
  prodType: string;
  prodName: string;
  uom: string;
}
export interface ProductUpdate {
  productId: number;
  prodgroupid: number;
  prodtypeid: number;
  prodName: string;
  custProdCd: string;
  productDetail: string;
  uomcd: number;
  prodLength: number;
  prodWidth: number;
  prodHeight: number;
  prodGrossWeight: number;
  prodWeight: number;
  color: string;
  belongsto: string;
  itemtype: string;
  backorderitem: number;
  singleunititem: number;
  isBom: number;
  hired: boolean;
  qtyhired: number;
  loadmeter: number;
  ownerType: number;
  pricingType: number;
  extProductNo: string;
  extProductName: string;
  reOrderLevelQty: number;
  minStockQty: number;
  maxStockQty: number;
  minOrderQty: number;
  maxOrderQty: number;
  avgLeadTime: number;
  // attachFiles: Array<string>,
  negativeStock: number;
  trading: number;
  service: number;
  productCd: number;
  active: number;
}

export interface ProductFile {
  productId: number;
  documentId: number;
  filePath: string;
}
export interface updateProductFile {
  productId: number;
  attachFiles: string;
}
