import { Directive, Self, OnDestroy, OnInit, ElementRef, Input, Renderer2, AfterViewInit, OnChanges, EventEmitter } from '@angular/core';
import { NgControl, FormControl, FormGroupDirective, NgForm, AbstractControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatError } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { CapitalizePipe } from './../../pipe/capitalize.pipe';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { TranslatePipe } from '@ngx-translate/core';

export class DefaultErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && ((control.dirty && control.touched) || isSubmitted));
  }
}


@Directive({
  selector: '[formControl], [formControlName]',
})

export class ValidationDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('refreshRequired') required: EventEmitter<void>;
  @Input('error') matErrorRef: MatError;
  private destroy = new Subject<void>();
  private subscription: Subscription;

  constructor(@Self() private control: NgControl,
    private elementRef: ElementRef,
    private capitalize: CapitalizePipe,
    private renderer: Renderer2,
    private translatePipe: TranslatePipe
  ) {

  }

  ngAfterViewInit() {
    this.markIfRequired();
  }

  markIfRequired() {

    if (this.control.control.validator) {
      const validator = this.control.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        const input = <HTMLElement>this.elementRef.nativeElement;
        const label = input.parentElement.querySelector('mat-label');
        if (label) {
          this.renderer.addClass(label, 'required');
        }
      } else {
        const input = <HTMLElement>this.elementRef.nativeElement;
        const label = input.parentElement.querySelector('mat-label');
        if (label) {
          this.renderer.removeClass(label, 'required');
        }
      }
    }
  }


  ngOnInit() {

    if (this.required) {
      this.subscription = this.required.subscribe(() => {
        this.markIfRequired();
      });
    }

    const input = <HTMLElement>this.elementRef.nativeElement;
    const errorElement = this.matErrorRef as HTMLElement;
    if (this.control) {

      let propertyName = input.getAttribute('name');
      if (propertyName == undefined || propertyName == "") {
        propertyName = this.capitalize.transform(this.control.name.toString());
      } else {
        propertyName = this.capitalize.transform(propertyName);
      }
      if (errorElement) {
        this.control.valueChanges?.pipe(
          takeUntil(this.destroy)
        ).subscribe((s) => {
          const errors = this.control.errors;
          // if (!environment.production && errors) {
          //   console.log(errors);
          // }
          if (errors) {
            //console.log(errors);
            let errorMeassage = "";
            if (errors?.required) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.isRequired')}`;
            }
            if (errors?.email) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.isNotValid')}`;
            }
            if (errors?.minlength) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.mustBeAtLeast')} ${errors?.minlength?.requiredLength} ${this.translatePipe.transform('common.charactersLong')}`;
            }
            if (errors?.maxlength) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.maxUpto')} ${errors?.maxlength?.requiredLength} ${this.translatePipe.transform('common.charactersLong')}`;
            }
            if (errors?.number) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.isNotValid')}`;
            }
            if (errors?.min) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.cantLessThan')} ${errors?.min.min}`;
            }
            if (errors?.max) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.maxUpto')} ${errors?.max.max}`;
            }
            if (errors?.positive) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.mustBePositiveValue')}`;
            }
            if (errors?.matDatepickerMin) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.mustBeGraterThan')} ${moment(errors?.matDatepickerMin.min).subtract(1, 'day').format("DD/MM/YYYY")}`;
            }
            if (errors?.integer) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${propertyName} ${this.translatePipe.transform('common.isNotValid')}`;
            }
            if (errors?.custom) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${errors?.custom}`;
            }
            this.renderer.setProperty(errorElement, 'innerHTML', errorMeassage);
            //errorElement.innerHTML = errorMeassage;

          } else {
            errorElement.innerText = "";
          }
        });
      }

    } else {
      throw Error("Validation is not working");
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
