import { Component, OnInit,Input,Output, EventEmitter,ViewChild } from '@angular/core';
import { PageSettingsModel, PageService, FilterService, SortService, parentsUntil, GridComponent, DetailRowService, RecordDoubleClickEventArgs, ContextMenuService, ContextMenuItemModel, ContextMenuClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { WarehouseService,DispatchOrderList } from 'src/app/services/warehouse/warehouse.service';

@Component({
  selector: 'pp-pull-dispath-order-dialog',
  templateUrl: './pull-dispath-order-dialog.component.html',
  styleUrls: ['./pull-dispath-order-dialog.component.scss'],
})
export class PullDispathOrderDialogComponent implements OnInit {
  @Output('data') data: EventEmitter<string>;
  @Input('selectedList') selectedList: Array<DispatchOrderList>;

  @ViewChild('grid') grid: GridComponent;
  public pageSettings: PageSettingsModel;
  public dateFormat: object;
  public customAttributes: Object;
  public contextMenuItems: ContextMenuItemModel[] = [];
  constructor() { 
    this.data = new EventEmitter();
  }

  ngOnInit(): void {
  }
  rowSelected(event: RecordDoubleClickEventArgs){

  }
}
