<div class="text-right mt-4"> 
  <button style="margin-left: 98%;margin-top:-10% ; color: red;font-weight: bold;cursor: pointer;" (click)="onNoClick()" >X</button> 
</div>
<h4 mat-dialog-title>Slots Details </h4>
<form #slotmanagementFormTemplate="ngForm" 
                     autocomplete="off">

                     
<div class="mb-2" mat-dialog-content>
  <pp-loading [show]="loading" [message]="'Loading...'"></pp-loading>
  <div class="row sm-gutters">
  <mat-form-field appearance="outline" class="col-md-5">
    <mat-label>{{'Location Name' | translate}}</mat-label>
    <mat-select [error]="" (selectionChange)="onStorageLocationChange($event)"
        formControlName="locationName" name="locationName">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let c of whLocationData" [value]="c.id">
            {{c.locationName}}
        </mat-option>
        
    </mat-select>
    <mat-error #locationName></mat-error>
</mat-form-field>


<mat-form-field appearance="outline" class="col-md-6">
  <mat-label>{{'common.product' | translate}}</mat-label>
  <mat-select [error]="" 
      formControlName="productName" name="productName">
      
      <mat-option *ngFor="let c of pulledDispatchOrderDetail" [value]="c.productCd">
          {{c.productName}}
      </mat-option>
      
  </mat-select>
  <mat-error #locationName></mat-error>
</mat-form-field>


  </div>


        <div class="wizard-container">
  <div class="row">  

    <div class="col-sm-8" style="max-width: 12rem;" *ngFor="let loc of whslotdata">
      <div class="card card-stats">
        <div class="card-header  card-header-icon" >
          <div class="card-icon" style="background-color:lightskyblue;" layout-align="center center">
           <mat-checkbox formControlName="locationDefault" [error]="locationDefault" style="margin:2px 8px auto 0;">
            </mat-checkbox>
           
          </div>
          <p class="card-category">{{ loc.locationSlotNo }} </p>
          <h6 class="card-category">{{ loc.capacity }}</h6>
          <div class="d-flex justify-content-between">
            <span>   <mdb-form-control>
              <input mdbInput type="text" autocomplete="off" id="formControlLg" class="form-control form-control-lg" style="width:80% ;height:132% ;background-color:azure"/>
             
            </mdb-form-control> </span> 
            <span>  <button style="color: blue;font-weight: bold;cursor: pointer;background-color:azure; margin-top:10px ;height: 28px;width:28px;border-radius: 50%;border: 1px solid lightblue;"  >+</button> </span>
          </div>
         
           




           
     
        </div>        
        <div class="card-footer">
          <div class="stats">
            <i class="material-icons"></i>  <p class="card-category"> Leer </p> &nbsp;&nbsp;
            <p class="card-category"> 

              <a [routerLink]=""
queryParamsHandling="preserve"
(click)="openSlotstrandtl(2)">Details</a>
            
            
</p> 
          
          
          </div>
          
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            
            <th>{{ "common.product" | translate }}</th>
            <th>Slot Nr.</th>
           
            <th>{{ "common.quantity" | translate }}</th>
          </tr>
        </thead>
        <tbody class="table-data">
          <tr class="bg-light" >
            <td style="vertical-align: middle">
              Euro Ohne Rand
            </td>
            <td style="vertical-align: middle">
            D1
            </td>
           
              <td style="vertical-align: middle">
              15
              </td>
          </tr>
        </tbody>
      </table>
    </div>

    

    
</div>

</div>
      
</div>
</form>



  <div class="text-right mt-4">
    <button
      type="button"   (click)="onNoClick()"             class="btn-pp"
      mat-flat-button
      color="accent" 
    >
    {{ "common.save" | translate }}
    </button>

    
  </div>

  


       
     

      
   
 
    


