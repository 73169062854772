<!-- <nav #navbar [ngClass]="{'fixed-top':fixedTop}" class="navbar navbar-expand-lg navbar-transparent navbar-absolute"></nav> -->
<nav #navbar class="navbar navbar-expand-lg fixed-top navbar-absolute">
  <div class="container-fluid">
    <button
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="navbar-wrapper">
      <div class="navbar-minimize d-none">
        <button
          mat-flat-button
          (click)="toggleSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </button>
      </div>
      <a class="navbar-brand" routerLink="/">
        <img src="/assets/logo/brand.png" alt="Brand Logo" title="PackPlan" />
      </a>
    </div>
    <!-- <div class="navbar-wrapper">
      <input type="text" name="search">
    </div> -->
    <!-- <div class="collapse navbar-collapse justify-content-end" id="navigation"></div> -->
    <div
      class="collapse navbar-collapse justify-content-between"
      id="navigation"
    >
      <form class="navbar-form" [formGroup]="quickActionForm">
        <!-- <div class="input-group no-border"> -->
        <input
          formControlName="search"
          #quickAction
          autocomplete="off"
          type="text"
          class="form-control"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          placeholder="Search here...."
        />
        <i class="material-icons">search</i>
        <!-- </div> -->
        <ng-container *ngIf="quickActionResult | async as searchResult">
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayWidth]="350"
            [cdkConnectedOverlayOpen]="searchResult.length"
          >
            <ul
              *ngIf="searchResult.length"
              class="quick-action-list list-unstyled"
            >
              <li
                *ngFor="let item of searchResult"
                (click)="onQukckAction(item.link)"
              >
                <i *ngIf="item.icon" class="material-icons">{{ item.icon }}</i>
                <span class="badge badge-danger" *ngIf="item.ab">{{
                  item.ab
                }}</span>
                <span class="badge badge-primary" *ngIf="item.type">{{
                  item.type
                }}</span>
                {{ item.text }}
              </li>
            </ul>
          </ng-template>
        </ng-container>
      </form>
      <div class="col-md-2"></div>
      <div class="col-md-3">

      
      <button routerLink="/warehouse/dashboard"  [hidden]="dashboardbutton" color="primary" class="btn-pp" mat-raised-button>Dashboard</button></div>
      <div style="display: flex">
        <ul class="navbar-nav mr-5">
          <!-- <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li> -->
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              href="#pablo"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="material-icons">notifications</i>
              <span *ngIf="notification.length" class="notification">{{
                notification.length
              }}</span>
              <p>
                <span class="d-lg-none d-md-block">Some Actions</span>
              </p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right notification-details"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                *ngFor="let item of notification"
                class="dropdown-item"
                (click)="onNotification(item)"
                href="javascript:void(0)"
              >
                {{ item.message }} &nbsp;&nbsp; <br />
                <span class="notification-time">
                  <i class="fa fa-clock-o"></i>
                  {{ item.transDatetime | amTimeAgo }}</span
                >
              </a>
            </div>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="#pablo">
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a> -->
            <!-- <button title="Logout" mat-icon-button routerLink="/auth/login">
              <mat-icon>exit_to_app</mat-icon>
            </button> -->

            <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User">
            <mat-icon>account_circle</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>account_circle</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item>
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>            
            <button routerLink="/auth/login" mat-menu-item>
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu> -->
          </li>
        </ul>
        <div class="user-menu">
          <img class="img-fluid" src="/assets/img/user.png" alt="user image" />
          <a href="#">
            {{ currentUser.userName }}
            <i
              class="fa fa-caret-down"
              type="button"
              class="dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="sr-only">Toggle Dropdown</span>
            </i>
            <div class="dropdown-menu" style="left: auto">
              <a
                matRipple
                (click)="logout()"
                href="javascript:void(0)"
                class="nav-link d-flex p-1"
              >
                <i class="material-icons">exit_to_app</i>
                <p class="px-2" style="text-transform: capitalize">
                  {{ "menu.logout" | translate }}
                </p>
              </a>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
