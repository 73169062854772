import { Component, OnInit } from '@angular/core';
import {
  PageSettingsModel,
  PageService,
  FilterService,
  SortService,
  parentsUntil,
  GridComponent,
  DetailRowService,
  RecordDoubleClickEventArgs,
  RecordClickEventArgs,
  ContextMenuService,
  ContextMenuItemModel,
  ContextMenuClickEventArgs,
  EditService,
} from '@syncfusion/ej2-angular-grids';
import { Router,ActivatedRoute } from '@angular/router';
import { WarehouseService,StockCorrectionList } from 'src/app/services/warehouse/warehouse.service';
@Component({
  selector: 'pp-stock-correction-list',
  templateUrl: './stock-correction-list.component.html',
  styleUrls: ['./stock-correction-list.component.scss']
})
export class StockCorrectionListComponent implements OnInit {
  public customAttributes: Object;
  public pageSettings: PageSettingsModel;
  whid:number=0;
  public StCorrectionlist:Array<StockCorrectionList>=[];
  public dateFormat: object;
  constructor(
    private router: Router,
    private warehouseservice: WarehouseService,

  ) { }

  ngOnInit(): void {
    this.dateFormat = { type: 'date', format: 'dd/MM/yyyy' };
    this.pageSettings = { pageSize: 20 };
    this.customAttributes = { class: 'pp-grid-style' };
    this.warehouseservice
    .GetStockCorrectionListrByWHID(this.whid)
    .subscribe(
      (data) => {
        this.StCorrectionlist = data

        //this.loading = false
      },
      (err) => {
        //this.message = "Unable to load data";
      },
    )

  }

}
