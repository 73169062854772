import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { settings } from './../../settings/settings';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../models/user/user';
import { CommonService } from '../common/common.service';
import { Login } from '../../models/accounts/login';
import { Register } from '../../models/accounts/register';
import { JwtToken } from '../../models/accounts/token';
import { PasswordReset } from '../../models/accounts/passwordReset';
import { UserService } from '../user/user.service';
import { SetPassword } from '../../models/accounts/setPassword';
import { AES, enc, mode, pad } from 'crypto-js';
import { passwordChange } from '../../models/accounts/passwordChange';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private userService: UserService
  ) {

  }

  encrypt(text: string) {
    if (text == null) return null;
    var encrypted = AES.encrypt(enc.Utf8.parse(text), enc.Utf8.parse(environment.CryptoJS.key),
      {
        keySize: environment.CryptoJS.keySize,
        iv: enc.Utf8.parse(environment.CryptoJS.iv),
        mode: mode.CBC,
        padding: pad.Pkcs7
      }).toString();

    return encrypted;
  }

  decrypt(encryptedText: string) {

    if (encryptedText == null) return null;

    var bytes = AES.decrypt(encryptedText, enc.Utf8.parse(environment.CryptoJS.key),
      {
        keySize: environment.CryptoJS.keySize,
        iv: enc.Utf8.parse(environment.CryptoJS.iv),
        mode: mode.CBC,
        padding: pad.Pkcs7
      });

    const decrypted = bytes.toString(enc.Utf8);

    return decrypted;
  }

  login(user: Login): Observable<string> {
    const requestUrl = `/api/Auth/Login`;
    const model = { uid: user.email, pwd: this.encrypt(user.password) };
    return this.http.post<string>(requestUrl, model);
  }
 saveToken(token: string): Observable<void> {

    localStorage.setItem(settings.userToken, token);

    const completed = new Subject<void>();


    this.userService.getProfile().subscribe(user => {

      localStorage.setItem(settings.currentUser, JSON.stringify(user));
      

      completed.next();
      completed.complete();
      if(user.whid!=null)
      {
        this.router.navigate(['/warehouse/dashboard']);
      }
      else
      {
      this.router.navigate(['/']);
      }
    });

    return completed;
  }

  getFreshToken() {
    const requestUrl = '/api/Auth/GetFreshToken';
    return this.http.post<JwtToken>(requestUrl, null);
  }

  register(user: Register) {
    const requestUrl = '/api/Auth/Register';
    return this.http.post(requestUrl, user);
  }

  logout(reason: 'sessionExpaired' | 'logout' | 'unauthorize') {
    // const requestUrl = '/api/Auth/Logout';
    // this.http.post<JwtToken>(requestUrl, null).subscribe(() => {
    //   console.log('Session Killed');
    // });
    localStorage.removeItem(settings.userToken);
    localStorage.removeItem(settings.currentUser);
    switch (reason) {
      case "logout":
        this.router.navigate(['/auth/logout']);
        break;
      case "sessionExpaired":
        this.router.navigate(['/auth/session-expired']);
        break;
      default:
        this.router.navigate(['/auth/login']);
        break;
    }

    // if(showMessage) this.toastrService.show("You have logged out successfully", "Logout !");
  }

  isLoggedIn(): boolean {
    if (this.getToken() && this.currentUser()) {
      return true;
    } else {
      return false;
    }
  }

  hasCorporateId() {
    const corporateId = localStorage.getItem(settings.corporateId);
    if (corporateId) return true;
    return false;
  }

  getCorporateId(): string {
    const corporateId = localStorage.getItem(settings.corporateId);
    if (corporateId) return corporateId;
    return "";
  }

  setCorporateId(corporateId): void {
    localStorage.setItem(settings.corporateId, corporateId);
  }

  currentUser(): User {
    return JSON.parse(localStorage.getItem(settings.currentUser)) as User;
  }

  // get any profile (defaults to own profile)


  getToken(): string {
    return localStorage.getItem(settings.userToken)
  }
  // return true if token need to store in local storage

  sendEmailConfirmation() {
    const requestUrl = '/api/Auth/SendEmailConfirmation';
    return this.http.post<boolean>(requestUrl, null);
  }

  verifyEmail(email: string, token: string) {
    const data = {
      email,
      token,
    };
    const requestUrl = '/api/Auth/ConfirmEmail';
    return this.http.post<void>(requestUrl, data);
  }

  forgotPassword(email: string) {
    const data = {
      uid: email
    };
    const requestUrl = '/api/Auth/ForgetPassword';
    return this.http.post<string>(requestUrl, data);
  }

  checkResetPasswordToken(token: string, userId: string, email: string) {
    const data = {
      token,
      userId,
      email
    };
    const requestUrl = '/api/Auth/CheckPasswordResetTokenIsValid';
    return this.http.post<boolean>(requestUrl, data);
  }

  resetPassword(model: PasswordReset) {
    const requestUrl = '/api/Auth/ResetPassword';
    return this.http.post<boolean>(requestUrl, model);
  }

  setPassword(model: SetPassword) {
    const requestUrl = '/api/Auth/SetPassword';
    return this.http.post<string>(requestUrl, model);
  }

  getNotifications() {
    const currentUser = this.currentUser();
    const requestUrl = `/api/Notification/GetNotificationByUserId/${currentUser.userId}`;
    return this.http.get<Array<string>>(requestUrl);
  }
  changePassword(model: passwordChange) {
    const requestUrl = '/api/Auth/ChangePassword';
    return this.http.post<string>(requestUrl, model);
  }
}
