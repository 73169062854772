
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import {
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  ContextMenuService,
  FilterService,
  FilterSettingsModel,
  GridComponent,
  PageService,
  PageSettingsModel,
  RecordDoubleClickEventArgs,
  SortService,
  ToolbarService,
  EditSettingsModel,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ProductService,ProductDropdown } from 'src/app/services/product/product.service';
import { DialogData, MessageDailogComponent } from 'src/app/component/shared/message-dailog/message-dailog.component';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CoreMasterService } from 'src/app/services/core-master/core-master.service';
import { ConfirmDailogComponent } from 'src/app/component/shared/confirm-dailog/confirm-dailog.component';
//import { CoreMasterService } from './../../../../services/core-master/core-master.service';
import {
  
  WarehouseService,
  WarehouseLocationSelection,
  WHLocationCreateModel,
  WHLocationSlotCreateModel,
  WHLocationslotModel,
  LocationProductStatus,
  WHLocationModel,
  LocationProductStatusModel,
  WHStockSheetModel,
} from 'src/app/services/warehouse/warehouse.service';
import {
  BusinessTypeList,
 
  DeliveryTerms,
  RetailerList,
  ServiceType,
} from 'src/app/services/core-master/core-master.service';
import { AlltransactionsdtlComponent } from '../../shared/alltransactionsdtl/alltransactionsdtl.component';
import { Table } from '@syncfusion/ej2-angular-richtexteditor';
@Component({
  selector: 'pp-slalldetaildialog',
  templateUrl: './slalldetaildialog.component.html',
  styleUrls: ['./slalldetaildialog.component.scss'],
  
  providers: [
    MatTabGroup,
       
    MatDialog
  ],
})
export class SlalldetaildialogComponent implements OnInit {

  data
 
  constructor(
    public dialogRef: MatDialogRef<SlalldetaildialogComponent>,
    private warehouseService: WarehouseService,
    private  productService :ProductService,
    private fb: FormBuilder,

    private _snackBar: MatSnackBar,
    private coreMasterService: CoreMasterService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public LocName: any,
    @Inject(MAT_DIALOG_DATA) public type: any,
    @Inject(MAT_DIALOG_DATA) public whid: any,
    @Inject(DOCUMENT) private _document: Document
  ) { }
  warehouselocationNo: Array<WHLocationslotModel> = [];
  updatedwhslotdata: Array<WHLocationslotModel> = [];
  stocksheetList: Array<WHStockSheetModel>=[];
storagelocationlist:Array<WHLocationModel>=[];

productDisplayName = [];
  WHLocationModel
  storagelocationproductmapping:Array<LocationProductStatusModel>=[];
  storagelocationproductmappingold:Array<LocationProductStatus>=[];
  instockquantity:number=0;
  businessPartnerId:number;
  buttonname:string;
  productSelected: Array<string>;
  processing: boolean = false;
  productDisplay: string = '';
  public editSettings?: EditSettingsModel;
  businessPartnerList: Array<RetailerList> = [];
  businessPartnerListFiltered: RetailerList[];
  StoragelocationForm: FormGroup;
  newslotform: FormGroup;
  public toolbar?: ToolbarItems[];
  selectedIndex:number=0;
  ischange:boolean=false;
  isslotdetail:boolean=false;
  products: Array<ProductDropdown> = [];
  filterProductlist: Array<ProductDropdown> = [];
  FilteredWareHouseStockList : Array<WHStockSheetModel>=[];
 
  ngOnInit(): void {

    this.StoragelocationForm = this.fb.group({
      locationname: ['', Validators.required],
      locationNo: ['', ""],
     
    });
    this.newslotform = this.fb.group({
      slotname: ['', Validators.required],
      slotcapacity: ['', Validators.required],
     
    });

    this.coreMasterService.getGetDebtorsList().subscribe((data) => {
      this.businessPartnerList = data;
      
    });
    this.productService.productDropdown().subscribe((data) => {
      this.products = data;
      this.filterProductlist=this.products;
    });
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true };
    this.toolbar = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    console.log(this.LocName.LName);
    console.log(this.whid.whid);
    
    console.log('type',this.type.type);
    if(this.type.type=="Change" || this.type.type=="Create")
    {
      if( this.type.type=="Create")
      {
        this.buttonname="Anlegen";
      }
      this.ischange=false;
      this.selectedIndex=1;
      this.isslotdetail=true;
    }
    if(this.type.type=="SlotDtl")
    {
      this.selectedIndex=0;
      this.ischange=true;
      this.isslotdetail=false;
    }
    if(this.type.type!="Create")
    {
      this.buttonname="Update";
    this.warehouseService.GetWarehouseLocationByLocationId(this.LocName.LName).subscribe((data) => {
      console.log('data',data);
    this.storagelocationproductmappingold=data.locationProductStatuses;
    console.log('this.storagelocationproductmappingold',this.storagelocationproductmappingold);
    this.StoragelocationForm.get("locationname").setValue(data.locationName);
    this.StoragelocationForm.get("locationNo").setValue(data.locationNo);
    this.businessPartnerId=0;
    });
    
    this.GetWareHouseLocSlotsWHIdandLocationID();
  }
  } 
  GetWareHouseLocSlotsWHIdandLocationID() {
    
    this.warehouseService
    .GetWareHouseLocSlotsWHId(this.whid.whid)
   .subscribe((Data) => {

    this.warehouselocationNo = Data.filter(x=>x.locationId==this.LocName.LName);
      console.log(this.warehouselocationNo);
    this.warehouseService.GetWareHouseStockSheetWHId(this.whid.whid).subscribe( (datastock) => {
     // this.stocksheetList = data;
   
      console.log('datastock',datastock);
      //this.warehouseService
     // .GetWareHouseLocSlotsWHIdandLocationID(this.whid.whid,this.LocName.LName)
    //.subscribe((SLData) => {
    //  this.warehouselocationNo = SLData;
      console.log(this.warehouselocationNo);
      this.warehouselocationNo.forEach((slotdata) => {
        this.instockquantity=0;
        this.FilteredWareHouseStockList=[];
        this.FilteredWareHouseStockList= datastock.filter(x=> x.SlotId == slotdata.slotId && x.inStockQty>0);
        for (let i = 0; i < this.FilteredWareHouseStockList.length; i++) {
          this.instockquantity=this.instockquantity+this.FilteredWareHouseStockList[i].inStockQty
        }
        slotdata.insqty=this.instockquantity
      })
    //  this.loading = false;
      })
    })

  }
  onNoClick(): void {
    this._document.defaultView.location.reload();
    this.dialogRef.close();
  }
  filterPartner(event) {
    if (event.target.value)
   
      this.businessPartnerListFiltered = this.businessPartnerList.filter((e) =>
        e.retailerName.toLowerCase().includes(event.target.value.toLowerCase())
      );
   
  }
  displayFn(value: any) {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((prd, index) => {
        if (index === 0) {
          displayValue = prd.prodName;
        } else {
          displayValue += ', ' + prd.prodName;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  UpdateStorageLocation() {
    this.processing = true;
    debugger;
  
    let data: WHLocationCreateModel = {
      id:this.LocName.LName,
      whid:this.whid.whid,
      locationName:this.StoragelocationForm.get("locationname").value,
      locationNo:this.StoragelocationForm.get("locationNo").value,

      isDefault:true,
      locationProductStatuses:this.storagelocationproductmapping
    }
         console.log('data',data);
         if(this.type.type=="Change")
         { 

    this.warehouseService.UpdateWarehouseLocation(data).subscribe(
      (s) => {
        if (s.update == true) {
          const message =s.message;
          this._snackBar.open(message, 'Close', {
            duration: 10000,
          });
          // this.router.navigate(['/purchase/requisition/list']);
          this.warehouseService.GetWarehouseLocationByLocationId(this.LocName.LName).subscribe((data) => {
            this.storagelocationproductmappingold=data.locationProductStatuses;
            console.log('this.storagelocationproductmappingold',this.storagelocationproductmappingold);
            this.StoragelocationForm.get("locationname").setValue(data.locationName);
            });
            this.GetWareHouseLocSlotsWHIdandLocationID();
            this.productDisplay="";
            this.productDisplayName=[];
            this.businessPartnerId=0;   
        }
      });
      this.processing = false;
    }
    else
    {
      this.warehouseService.CreateWarehouseLocation(data).subscribe(
        (s) => {
          if (s.insert == true) {
            const message =s.message;
            this._snackBar.open(message, 'Close', {
              duration: 10000,
            });
            // this.router.navigate(['/purchase/requisition/list']);
            this.warehouseService.GetWarehouseLocationByLocationId(this.LocName.LName).subscribe((data) => {
              this.storagelocationproductmappingold=data.locationProductStatuses;
              console.log('this.storagelocationproductmappingold',this.storagelocationproductmappingold);
              this.StoragelocationForm.get("locationname").setValue(data.locationName);
              });
              this.GetWareHouseLocSlotsWHIdandLocationID();
              this.productDisplay="";
              this.productDisplayName=[];
              this.businessPartnerId=0;   
          }
        });
        this.processing = false;
    }
  }
  onBusinessPartner(event: any) {
    if (event.option.id) {
      this.businessPartnerId = event.option.id;
      this.processing = true;
        this.products=[];
      const businessTypeId = 2;
      if (businessTypeId && event.option.id) {
        this.productService
          .GetProductsByBusinessTypeBP(businessTypeId, event.option.id)
          .subscribe((data) => {
            this.products = data;
            this.filterProductlist=this.products;
            this.processing = false;
           // this.productEntryForm.get('product').setValue('');
          });
      } else {
        // console.log(businessTypeId, event.option.id)
      }
    }
  }
  optionClicked(event: Event, prd: any) {
    //  this.getWareHouseStockSheet();
    
      event.stopPropagation();
      this.toggleSelection(prd);
   
    }
    toggleSelection(prd: any) {

  debugger;
      console.log('prd',prd)
      prd.selected = !prd.selected;
      if (prd.selected) {
        let prodselected: LocationProductStatusModel=
        {
          id:0,
          storageLocationId:this.LocName.LName,
          statusCd:1,
          BPID:this.businessPartnerId,
          ProductCD:prd?.productCd
        }
       
        this.productDisplayName.push(prd?.prodName);
        let prodname:LocationProductStatus=
        {
          id:0,
          statusName:this.LocName.LName,
          statusCd:1,
          BPID:this.businessPartnerId,
          productName:prd?.prodName,
        }
        console.log('prodname',this.productDisplayName)
        console.log('this.productDisplayName',this.productDisplayName)
        const j = this.productDisplayName.findIndex(
          (value: any) => value.prodName === prd.prodName
        );
       // this.productDisplayName.splice(j, 1);
     //   const ipr = this.storagelocationproductmappingold.findIndex(
     //     (value: any) => value.productName === prd.productName
      //  );
      
     //  if(ipr<0)
     //  {
    //    const message ="Product allready mapped!";
     //   this._snackBar.open(message, 'Close', {
      //    duration: 10000,
      //  });
      //  this.storagelocationproductmappingold.splice(ipr, 1);
      // }
      // else
      // {
        this.storagelocationproductmapping.push(prodselected);
        this.storagelocationproductmappingold.push(prodname)
    //   }
        this.productDisplay = this.productDisplayName.join();
        
      } 
      else
      {
        const ipr = this.storagelocationproductmappingold.findIndex(
          (value: any) => value.productName === prd.productName
        );
      
      
        this.storagelocationproductmappingold.splice(ipr, 1);
        this.storagelocationproductmapping.splice(ipr, 1);
        const j = this.productDisplayName.findIndex(
          (value: any) => value.prodName === prd.prodName
        );
       
        this.productDisplayName.splice(j, 1);

      }
    
    console.log('this.storagelocationproductmapping',this.storagelocationproductmapping);
    }
  openSlotstrandtl( ) {
    
    //console.log('****', locationid)
    const DailogRef = this.dialog.open(AlltransactionsdtlComponent, {
      width: '70%',
      maxWidth: '100%',
      maxHeight: '100%',
      role: 'alertdialog',
      disableClose: true,
      data:{SlotID:0}
    //  data:LName
     
    });
  }

  openSlotStockdtl(slotid:number,SlotNo:string ) {
    
    //console.log('****', locationid)
    const DailogRef = this.dialog.open(AlltransactionsdtlComponent, {
      width: '70%',
      maxWidth: '100%',
      maxHeight: '100%',
      role: 'alertdialog',
      disableClose: true,
      data:{SlotID:slotid,SlotNum:SlotNo}
     
    });
  }
  filterProduct(event) {
    console.log('Productlist1',this.filterProductlist);
    if (event.target.value) { 
       this.products = this.products.filter((e) =>e.prodName.toLowerCase().includes(event.target.value.toLowerCase()));
    } 
    else
    {
      this.products=this.filterProductlist;
     console.log('Productlist',this.filterProductlist);
    }

  
  }
  toggleSelection1(prd: any) {

  
      
    prd.selected = !prd.selected;
    if (prd.selected) {
      this.productSelected.push(prd?.productCd);
      this.productDisplayName.push(prd?.prodName);
    } else {
      const i = this.productSelected.findIndex(
        (value: any) => value.productCd === prd.productCd
      );

      const j = this.productDisplayName.findIndex(
        (value: any) => value.prodName === prd.prodName
      );

      this.productSelected.splice(i, 1);
      this.productDisplayName.splice(j, 1);
    }
    this.productDisplay = this.productDisplayName.join();
   
  
  }

  UpdateLocationSlots(slotid:number,slotname:string,capacity:number)
  {
    const confirmData: DialogData = {
      messege: `Do you want to update slot`,
      title: "Are you sure ?"
    };
    const editDailogRef = this.dialog.open(ConfirmDailogComponent, {
      width: '400px',
      maxWidth: '100%',
      disableClose: true,
      data: confirmData
    });
    editDailogRef.afterClosed().subscribe(ans => {
      if (ans) {

        this.processing=true;
        let slotdetail:WHLocationSlotCreateModel=
            {
              slotId:slotid,
              locationSlotNo:slotname,
              whid:this.whid.whid,
              capacity:capacity,
              isDefault:true,
              locationID:this.LocName.LName,
          
            }
            console.log('slotdetail',slotdetail);
            this.warehouseService.UpdateWLocationSlot(slotdetail).subscribe(
              (s) => {
                if (s.update == true) {
                  const message =s.message;
                  this._snackBar.open(message, 'Close', {
                    duration: 10000,
                  });
                  // this.router.navigate(['/purchase/requisition/list']);
                  this.warehouseService.GetWarehouseLocationByLocationId(this.LocName.LName).subscribe((data) => {
                    this.storagelocationproductmappingold=data.locationProductStatuses;
                    console.log('this.storagelocationproductmappingold',this.storagelocationproductmappingold);
                    this.StoragelocationForm.get("locationname").setValue(data.locationName);
                    });
                    this.GetWareHouseLocSlotsWHIdandLocationID();
                   
                    this.processing=false;   
                }
              });
    
      } else {
      
        this._snackBar.open(`Action Canceled by user `, 'Close', {
          duration: 10000,
        });
      }
    
  })
}


CreateNewSlot(isValid:boolean)
  {
    if (isValid) {
      debugger;
    const confirmData: DialogData = {
      messege: `Do you want to create slot`,
      title: "Are you sure ?"
    };
    const editDailogRef = this.dialog.open(ConfirmDailogComponent, {
      width: '400px',
      maxWidth: '100%',
      disableClose: true,
      data: confirmData
    });
    editDailogRef.afterClosed().subscribe(ans => {
      if (ans) {

        this.processing=true;
        let slotdetail:WHLocationSlotCreateModel=
            {
              slotId:0,
              locationSlotNo:this.newslotform.get("slotname").value,
              whid:this.whid.whid,
              capacity:this.newslotform.get("slotcapacity").value,
              isDefault:true,
              locationID:this.LocName.LName,
          
            }
            console.log('slotdetail',slotdetail);
            this.warehouseService.CreateWarehouseLocationSlots(slotdetail).subscribe(
              (s) => {
                console.log('s',s);
                if (s.insert == true) {
                  
                  const message =s.message;
                  this._snackBar.open(message, 'Close', {
                    duration: 10000,
                  });
                  this.newslotform.get("slotname").setValue("");
                  this.newslotform.get("slotcapacity").setValue("");
                  // this.router.navigate(['/purchase/requisition/list']);
                  this.warehouseService.GetWarehouseLocationByLocationId(this.LocName.LName).subscribe((data) => {
                    this.storagelocationproductmappingold=data.locationProductStatuses;
                    console.log('this.storagelocationproductmappingold',this.storagelocationproductmappingold);
                    this.StoragelocationForm.get("locationname").setValue(data.locationName);
                    });
                    this.GetWareHouseLocSlotsWHIdandLocationID();
                   
                    this.processing=false;   
                }
              });
    
      } else {
      
        this._snackBar.open(`Action Canceled by user `, 'Close', {
          duration: 10000,
        });
      }
    
  })
}
}
}
