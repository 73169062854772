import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './../../../module/material/material.module';
import { DirectiveModule } from './../../../directive/directive.module';
import { PipeModule } from './../../../pipe/pipe.module';
import { SyncfusionGridModule } from './../../../../../src/app/module/grid/syncfusion-grid.module';
import { RouterModule } from '@angular/router';
import { StockCorrectionListComponent } from './opening-stock-correction-list/stock-correction-list.component';
//import { StockCorrectionListComponent } from './opening-stock-correction-list/stock-correction-list.componentnent';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    DirectiveModule,
    PipeModule,
   // StockCorrectionListComponent,
    SyncfusionGridModule
    
  ]
})
export class OpeningStockCorrectionModule { }
