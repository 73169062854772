import { Injectable } from '@angular/core';

import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { LanguageService } from './services/language/language.service';

@Injectable()
export class AppModuleLanguageResolver implements Resolve<number> {

    constructor(
        private translateService: TranslateService,
        private languageService: LanguageService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
        return this.languageService.loadLocalResource('menu');
    }
}