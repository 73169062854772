<div class="text-right mt-4"> 
  <button style="margin-left: 98%;margin-top:-10% ; color: red;font-weight: bold;cursor: pointer;" (click)="onNoClick()" >X</button> 
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
<h4 mat-dialog-title>Storage Location Details </h4>

    <mat-tab-group
   
    [selectedIndex]="selectedIndex"
      
      color="primary"
      animationDuration="0ms"
    >

    <div class="container-fluid">  
    <mat-tab [label]="'common.slotdetail' | translate | uppercase" [disabled]="isslotdetail">
      
     
        <div class="wizard-container">
  <div class="row">
                                       
    <div class="col-sm-4" style="max-width: 9rem;height:9rem;" *ngFor="let loc of warehouselocationNo">
      <div class="card card-stats">
            <div class="card-header card-header-success card-header-icon" >
                <div class="card-icon">
                    <i class="mr-auto">   </i>
                      
                </div>
                
                <strong class="card-category">   {{ loc.locationSlotNo }}</strong> 
                <div class="card-footer">
                 
                <div class="stats">
                 
                  <strong class="card-category"> 
                    <a [routerLink]=""
                    queryParamsHandling="preserve"
                    (click)="openSlotStockdtl(loc.slotId,loc.locationSlotNo)">Stocks</a>
            
                    <strong class="card-category"> {{ loc.insqty}}  </strong> 
                    <a [routerLink]=""
   queryParamsHandling="preserve"
   (click)="openSlotstrandtl(2)">Details</a>
                  
                  
                  
                  </strong>
                
              </div>
                </div>
           
             
                
                
            </div>
      </div>
    </div>

    


</div>
      </div>
      <div class="text-right mt-4">
        <button
          type="button"   (click)="onNoClick()"             class="btn-pp"
          mat-flat-button
          color="accent" 
        >
        {{ "common.close" | translate }}
        </button>

        
      </div>

    </mat-tab>
   
 <mat-tab [label]="'common.storagelocationedit' | translate | uppercase" [disabled]="ischange">
  <form
  #prEntryForm="ngForm"
  [formGroup]="StoragelocationForm"
  autocomplete="off"
>
<pp-loading
[show]="processing"
[message]="'common.processing' | translate"
></pp-loading>

  <div class="row">
    <mat-form-field
    appearance="outline"
    class="col-md-2"
  >
    <mat-label>
      {{ "common.storagelocationname" | translate }}
    </mat-label>
    <input
      autocomplete="off"
      type="text"
      [error]="locationname"
      [name]="locationname"
      formControlName="locationname"
      [value]="warehouselocationNo[0]?.locationName"
      matInput
      placeholder="e.g Dr"
    />
    <mat-error #locationname></mat-error>
  </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-2">
            <mat-label>
              {{ "common.storagelocationshname" | translate }}
            </mat-label>
            <input
             spellcheck="false"
              autocomplete="off"
              type="text"
             
              formControlName="locationNo"
              matInput
           
            
            />
            <mat-error #locationNo></mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>
              {{ "common.business-partner" | translate }}
            </mat-label>
            <input
              [error]="businessPartner"
              formControlName="businessPartner"
              type="text"
              placeholder="Business Partner"
              aria-label="businessPartner"
              matInput
              [matAutocomplete]="businessPartner"
              (input)="filterPartner($event)"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #businessPartner="matAutocomplete"
              [attr.name]="'common.business-partner' | translate"
              (optionSelected)="onBusinessPartner($event)"
            >
              <mat-option value="">SELECT BUSINESS PARTNER</mat-option>
              <mat-option
              *ngFor="let retailer of businessPartnerListFiltered"
              [value]="retailer.retailerName"
              [id]="retailer.id"
            >
              {{ retailer.retailerName }}
            </mat-option>
            </mat-autocomplete>

            <mat-error #businessPartner></mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>
              {{ "common.product" | translate }}
            </mat-label>
            <input
            type="text"
            placeholder="Produkt"
            aria-label="product"
            spellcheck="false"
            matInput
            [matAutocomplete]="product"
            [(value)]="productDisplay"
            (input)="filterProduct($event)"
           
          />
         
          <mat-autocomplete
              #product="matAutocomplete"
              [displayWith]="displayFn"
              spellcheck="false"
             
            >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let prd of products"
              
            >
              <div (click)="optionClicked($event, prd)" >
                <mat-checkbox
                
                  
                  [checked]="prd.selected"
                  (change)="toggleSelection(prd)"
                  (click)="$event.stopPropagation()"
                >
                  {{ prd.prodName }}
                </mat-checkbox>
              </div>
            </mat-option>
            </mat-autocomplete>
           
            <mat-error #productId></mat-error>
          </mat-form-field>
          <div class="col mb-4 text-right px-3" style="padding-top: 3px;">
          <button
          class="btn-pp"
          type="submit"
          mat-raised-button
          color="accent"
          (click)="UpdateStorageLocation()"
        >
          {{buttonname}}
        </button>
          </div>
  </div>
  </form>
  <mat-tab-group
   
  [selectedIndex]="0"
    
    color="primary"
    animationDuration="0ms"
  >

  <mat-tab [label]="'common.BusinessPartner-Product' | translate" >
    <div class="col-12">
      <div class="table-responsive" style="height: 300px;">
        <table class="table">
         
         
           <thead>
             <tr>
               <th>{{ "common.slotname" | translate }}</th>
               <th>Produkt Name</th>
            
             </tr>
           </thead>
           <tbody>
             <tr
               
               *ngFor="
                 let locationno of storagelocationproductmappingold;
                 let i = index
               "
             >
              
          <td>
            {{locationno.bpName}}
          </td>
       
          <td>
            {{locationno.productName}}
          </td>
      

             
              
             </tr>
           </tbody>
         </table>
      
     
  
    

      </div>
  </div>
  </mat-tab>
  <mat-tab [label]="'Slot Name Detail' | translate" >
  <ng-container>
    <form
      #prEntryForm="ngForm"
      (isValid)="CreateNewSlot($event)"
      [formGroup]="newslotform"
     
      autocomplete="off"
    >
      <div class="row sm-gutters">
       
     
    
        <mat-form-field
          appearance="outline"
          class="col-md-3"
        >
          <mat-label>
           Slot Name
          </mat-label>
          <input
            autocomplete="off"
            type="text"
            [error]="slotname"
            [name]="slotname"
            formControlName="slotname"
            matInput
            placeholder="e.g D"
          />
          <mat-error #slotname></mat-error>
        </mat-form-field>
        <mat-form-field
        appearance="outline"
        class="col-md-3"
      >
        <mat-label>
         Slot Capacity
        </mat-label>
        <input
          autocomplete="off"
          type="text"
          [error]="slotcapacity"
          [name]="slotcapacity"
          formControlName="slotcapacity"
          matInput
          placeholder="e.g D"
        />
        <mat-error #slotcapacity></mat-error>
      </mat-form-field>
        <div class="col-md-2">
          <div class="text-right" style="padding-top: 3px;">
            <button
              class="pp-green"
              type="submit"
              mat-fab
              color="accent"
            >
              {{ "common.add" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
    <div class="row">
     
  <div class="col-12">
      <div class="table-responsive" style="height: 400px;">
          <table
           class="table table-striped"
         
         >
           <thead>
             <tr>
               <th>{{ "common.slotname" | translate }}</th>
               <th>{{ "common.slotcapacity" | translate }}</th>
            
             </tr>
           </thead>
           <tbody class="table-data" style="background-color: ghostwhite;">
             <tr
               
               *ngFor="
                 let locationno of warehouselocationNo;
                 let i = index
               "
             >
              
          <td>
              <input type="text" [(ngModel)]="locationno.locationSlotNo">
          </td>
       
          <td>
              <input type="text" [(ngModel)]="locationno.capacity">
          </td>
          <td>
              <div>
                <button mat-button color="primary" (click)="UpdateLocationSlots(locationno.slotId,locationno.locationSlotNo,locationno.capacity)"  > {{ "common.update" | translate }}</button>
                 
              </div>
          </td>
               

             
              
             </tr>
           </tbody>
         </table>
      
     
  
    

      </div>
  </div>
    </div>
  <div class="text-right mt-4">
    <button
      type="button"   (click)="onNoClick()"             class="btn-pp"
      mat-flat-button
      color="accent" 
    >
    {{ "common.close" | translate }}
    </button>

  </div>
</mat-tab>
  </mat-tab-group>
 </mat-tab>
</div>
       
    </mat-tab-group>

    </div>
  </div>
</div>
       
       
     

      
   
 
    


