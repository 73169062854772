import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { LanguageService } from './../../services/language/language.service';

@Injectable()
export class WarehouseModuleLanguageResolver implements Resolve<number> {

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {

        this.translateService.set('pur.common.requisition-no', `Requisition No`, 'en');
        this.translateService.set('pur.common.requisition-date', `Requisition Date`, 'en');
        this.translateService.set('pur.common.required-date', `Required Date`, 'en');
        this.translateService.set('pur.common.vendor-name', `Vendor Name`, 'en');
        this.translateService.set('pur.common.payment-terms', `Payment Terms`, 'en');
        this.translateService.set('pur.common.rfq-no', `RFQ No`, 'en');
        this.translateService.set('pur.common.rfq-date', `RFQ Date`, 'en');
        this.translateService.set('pur.common.customer', `Customer`, 'en');
        this.translateService.set('pur.common.customer-address', `Customer Address`, 'en');
        this.translateService.set('common.delivery-date', `Delivery Date`, 'en');
        this.translateService.set('common.warehouse', `Warehouse`, 'en');
        this.translateService.set('pur.common.ref-doc-no', `Ref Doc No`, 'en');
        this.translateService.set('pur.common.discount', `Discount`, 'en');
        this.translateService.set('pur.common.tax-rate', `Tax Rate`, 'en');
        this.translateService.set('pur.common.tax-amount', `Tax Amount`, 'en');
        this.translateService.set('pur.common.discount-amount', `Discount Amount`, 'en');
        this.translateService.set('pur.common.transport-cost', `Transport Cost`, 'en');
        this.translateService.set('pur.common.changed-delivery-date', `Changed Delivery Date`, 'en');
        this.translateService.set('pur.common.actual-qty', `Actual Qty`, 'en');
        this.translateService.set('pur.common.changed-qty', `Change Qty`, 'en');
        this.translateService.set('pur.product-code', `Product Code`, 'en');
        this.translateService.set('pur.vendor-product-code', `Vendor Product Code`, 'en');
        this.translateService.set('pur.validity', `Validity`, 'en');

        return this.languageService.loadLocalResource('pur');
    }
}