import { Component, OnInit, Input, ElementRef, OnChanges, AfterContentChecked, HostListener } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'pp-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnChanges {
  @Input('show') show = false;
  @Input('message') message: string;
  @Input('onlyOverlay') onlyOverlay: boolean = false;
  @Input('hideOverlay') hideOverlay: boolean = false;
  private el: HTMLDivElement;
  paddingLeft: string;
  constructor(
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
  }
  ngOnChanges() {
    if (this.show && this.el.parentElement.getBoundingClientRect().top < 0) {
      this.el.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

}
