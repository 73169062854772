import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  elementPermissionList: Array<{
    elementName: string;
    allowedRole: Array<string>;
  }>;
  pagePermissionList: Array<{ pageUrl: string; allowedRole: Array<string> }>;
  constructor(private authService: AuthService) {
    this.elementPermissionList = [];
    this.elementPermissionList.push({
      elementName: 'menu.master',
      //permissions:['insert','update','delete','view'],
      allowedRole: ['admin', 'superadmin'],
    });
    this.elementPermissionList.push({
      elementName: 'menu.appointment',
      allowedRole: ['admin'],
    });

    this.pagePermissionList = [];
    this.pagePermissionList.push({
      pageUrl: '/appointment',
      allowedRole: ['admin'],
    });
  }

  isAllowed(elementName: string): boolean {
    if (elementName === '' || elementName == null) {
      throw new Error('Element name is not supplyed !!');
    }

    const currentUser = this.authService.currentUser();
    const result = this.elementPermissionList.find((item) => {
      if (item.elementName.toLowerCase() === elementName.toLowerCase()) {
        return true;
      }
    });

    if (result) {
      if (result.allowedRole.find(role => { return currentUser.roles.includes(role) }) != undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isAllowedPage(pageUrl: string): boolean {
    if (pageUrl === '' || pageUrl == null) {
      throw new Error('pageUrl is not supplyed !!');
    }

    const currentUser = this.authService.currentUser();
    const result = this.pagePermissionList.find((item) => {
      if (item.pageUrl.toLowerCase() === pageUrl.toLowerCase()) {
        return true;
      }
    });

    if (result) {
      if (result.allowedRole.find(role => { return currentUser.roles.includes(role) }) != undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
