import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './component/layout/layout.component';
import { FooterComponent } from './component/layout/footer/footer.component';
import { HeaderComponent } from './component/layout/header/header.component';
import { SidebarComponent } from './component/layout/sidebar/sidebar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { FormErrorComponent } from './component/shared/form-error/form-error.component';
// import { InternalServerErrorComponent } from './component/shared/internal-server-error/internal-server-error.component';
// import { LoadingComponent } from './component/shared/loading/loading.component';
// import { OfflineComponent } from './component/shared/offline/offline.component';
// import { PageNotFoundComponent } from './component/shared/page-not-found/page-not-found.component';
// import { PermissionDeniedComponent } from './component/shared/permission-denied/permission-denied.component';
// import { SomethingWentWrongComponent } from './component/shared/something-went-wrong/something-went-wrong.component';
// import { ValidationComponent } from './component/shared/validation/validation.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { DefaultErrorStateMatcher } from './directive/validation/validation.directive';
import { MaterialModule } from './module/material/material.module';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { TokenInterceptor } from './services/auth/interceptor';
import { MessageDailogComponent } from './component/shared/message-dailog/message-dailog.component';
import { ConfirmDailogComponent } from './component/shared/confirm-dailog/confirm-dailog.component';
import { AskForPushNotificationComponent } from './component/shared/ask-for-push-notification/ask-for-push-notification.component';
import { MomentModule } from 'ngx-moment';
import { DashboardModule } from './component/dashboard/dashboard.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DirectiveModule } from './directive/directive.module';
import { PipeModule } from './pipe/pipe.module';
import { WarehouseLayoutComponent } from './component/layout/warehouse-layout/warehouse-layout.component';
import { SldashboradComponent } from './sldashborad/sldashborad.component';
import { SlalldetaildialogComponent } from './sldashborad/sldetail/slalldetaildialog/slalldetaildialog.component';
import { AlltransactionsdtlComponent } from './sldashborad/shared/alltransactionsdtl/alltransactionsdtl.component';
import { WhstockslotmanagementComponent } from './sldashborad/shared/alltransactionsdtl/whstockslotmanagement/whstockslotmanagement.component';
//import { OrderwashplanningComponent } from './component/production/orderwashplanning/orderwashplanning.component';
//import { StockCorrectionListComponent } from './component/warehouse/opening-stock-correction/opening-stock-correction-list/stock-correction-list.component';
//import { ProdslotsmanagemantComponent } from './component/warehouse/goods-receive/prodslotsmanagemant/prodslotsmanagemant.component';
//import { MultiOrderInsertComponent } from './component/logistics/order-process/multi-order-insert/multi-order-insert.component';

// import { TransactionCancelDialogComponent } from './component/shared/transaction-cancel-dialog/transaction-cancel-dialog.component'
import { NgChartjsModule } from 'ng-chartjs';
//import { ProductionpartreportComponent } from './Report/productionpartreport/productionpartreport.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({

  declarations: [
    AppComponent,
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    MessageDailogComponent,
    ConfirmDailogComponent,
    AskForPushNotificationComponent,
    WarehouseLayoutComponent,
    SldashboradComponent,
    SlalldetaildialogComponent,
    AlltransactionsdtlComponent,
    WhstockslotmanagementComponent,
    //ProductionpartreportComponent,
  //  OrderwashplanningComponent,
  
    //StockCorrectionListComponent,
    //ProdslotsmanagemantComponent,
    
    // TransactionCancelDialogComponent,   
    // FormErrorComponent,
    // InternalServerErrorComponent,
    // LoadingComponent,
    // PageNotFoundComponent,
    // PermissionDeniedComponent,
    // OfflineComponent,
    // SomethingWentWrongComponent,
    // ValidationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    PipeModule,
    DirectiveModule,
    NgChartjsModule.registerPlugin([]),
  
    TranslateModule.forRoot({
      // loader: {
      //   provide: TranslateLoader,
      //   useFactory: HttpLoaderFactory,
      //   deps: [HttpClient]
      // }
    }),
    ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59,
        'ss': 3
      }
    })
  ],
 
  providers: [
   
    { provide: ErrorStateMatcher, useClass: DefaultErrorStateMatcher },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
