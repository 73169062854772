import { Injectable, ApplicationRef } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { settings } from 'src/app/settings/settings';
import * as Bowser from "bowser"
import { SwUpdate } from '@angular/service-worker';
import { interval, concat } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private authService: AuthService,
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private snackBar: MatSnackBar,
  ) {

    if (environment.production) {
      if ("Notification" in window) {
        if (Notification.permission === "granted") {
          this.requestPermission();
          this.angularFireMessaging.messages.subscribe(
            data => {
              console.log(data);
            }
          )
        }
      }

      setTimeout(() => {

        this.updates.available.subscribe(event => {
          console.log('update available', event);
          this.snackBar.open(`Newer version (${event.available.hash}) is available, downloading in the background`, 'Close', {
            duration: 15000,
          });

          updates.activateUpdate().then(() =>
            setTimeout(() => {
              document.location.reload()
            }, 20000)
          );

          console.log('current version is', event.current);
          console.log('available version is', event.available);
        });

        this.updates.activated.subscribe(event => {
          console.log('after activate', event);
          setTimeout(() => {
            this.snackBar.open(`New version ${event.current.hash} is activated`, 'Close', {
              duration: 10000,
            });
          }, 5000)

          console.log('old version was', event.previous);
          console.log('new version is', event.current);
        });

        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(.5 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

        everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
      }, 5000);
    }
  }

  updateToken(token: string) {
    const info = Bowser.parse(window.navigator.userAgent);
    const currentUser = this.authService.currentUser();
    const url = `/api/AppDeviceDetail/CreateAppDeviceDetail`;
    const data = {
      userId: currentUser.userId,
      pushToken: token,
      lastAccessed: new Date(),
      appVersion: settings.appVersion,
      platformType: info.browser.name,
      platformVersion: info.browser.version,
      hardwareId: `${info.os.name} - ${info.os.version}`,
      hardwareModel: info.platform.type,
    };

    this.http.post(url, data).subscribe(resp => {
      console.log('Push token sent to server ', resp);
    })
  }

  requestPermission() {
    this.angularFireMessaging.requestToken
      .subscribe(
        (token) => {
          this.updateToken(token);
        },
        (error) => { console.error(error); },
      );
  }
}
