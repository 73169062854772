import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { merge, concat, Observable, of, Subscription, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  tap,
} from 'rxjs/operators';
import { RouteInfo } from '../sidebar/sidebar.component';

import {
  NotificationService,
  UserNotification,
} from './../../../services/notification/notification.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { CommunicationService } from './../../../services/communication/communication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user/user';
import { AuthService } from 'src/app/services/auth/auth.service';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;

export interface QuickAction {
  link: string;
  icon?: string;
  text: string;
  type: string;
  ab?: string;
}

@Component({
  selector: 'pp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  currentUser: User;
dashboardbutton:boolean=false;
  quickActionList: Array<QuickAction> = [];
  quickActionResult: Observable<Array<QuickAction>>;
  apiSearch: Subject<Array<QuickAction>>;
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  fixedTop: boolean = false;
  notification: Array<UserNotification> = [];
  subscription: Subscription;
  quickActionForm: FormGroup;
  @ViewChild('app-navbar-cmp', { static: false }) button: any;
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private notificationService: NotificationService,
    private angularFireMessaging: AngularFireMessaging,
    private communicationService: CommunicationService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.apiSearch = new Subject<Array<QuickAction>>();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    if ($(window).width() > 991) {
      this.fixedTop = false;
      return false;
    }
    this.fixedTop = true;
    return true;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    setTimeout(function () {
      body.classList.add('sidebar-mini');

      misc.sidebar_mini_active = true;
    }, 300);

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  toggleSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      console.log('1',1);
      setTimeout(function () {
        console.log('1',2);
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        console.log('1',3);
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');
    } else {
      setTimeout(function () {
        console.log('1',4);
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  private _quickActionFilter(value: string): Array<QuickAction> {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.quickActionList.filter(
        (option) =>
          option.ab?.toLowerCase().includes(filterValue) ||
          option.text.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }

  searchNotification(searchTerm: string) {
    console.log('apiSearch q', searchTerm);

    if (searchTerm && searchTerm.length > 2) {
      this.notificationService
        .searchNotification(searchTerm)
        .subscribe((searchResult) => {
          let quickAction: Array<QuickAction> =
            this._quickActionFilter(searchTerm);

          searchResult.notification.forEach((item) => {
            switch (item.refDocTypeId) {
              //purchase requisition
              case 91:
                quickAction.push({
                  text: item.message,
                  link: `/purchase/requisition/edit/${item.refDocNumber}`,
                  type: 'REQ',
                });
                break;
              //Request for Quotation
              case 92:
                quickAction.push({
                  text: item.message,
                  link: `/purchase/request-for-quotation/edit/${item.refDocNumber}`,
                  type: 'RFQ',
                });
                break;
              //Vendor Quotation
              case 93:
                quickAction.push({
                  text: item.message,
                  link: `/purchase/vendor-offer/edit/${item.refDocId}`,
                  type: 'VEO',
                });
                break;
              //Purchase Order
              case 94:
                quickAction.push({
                  text: item.message,
                  link: `/purchase/order/edit/${item.refDocNumber}`,
                  type: 'POR',
                });
                break;
            }
          });

          searchResult.productresult.forEach((item) => {
            quickAction.push({
              text: item.productDetail,
              link: `/master/product/edit/${item.productId}`,
              type: 'PRODUCT',
            });
          });

          searchResult.busiesspartner.forEach((item) => {
            quickAction.push({ text: item.bpname, link: ``, type: 'BP' });
          });

          this.apiSearch.next(quickAction);
        });
    }
  }

  ngOnInit() {
    debugger;
    this.currentUser = this.authService.currentUser();
    this.dashboardbutton=true;
if(this.currentUser.whid!=null)
{
  this.dashboardbutton=false;
}
    this.quickActionForm = this.fb.group({
      search: [''],
    });

    const localSearch = this.quickActionForm.get('search').valueChanges.pipe(
      debounceTime(100),
      map((value) => {
        this.searchNotification(value);
        return this._quickActionFilter(value);
      })
    );

    this.quickActionResult = merge(localSearch, this.apiSearch);

    this.subscription = this.communicationService.toggleSidebar.subscribe(
      () => {
        this.toggleSidebar();
      }
    );
    this.subscription = this.communicationService.minimizeSidebar.subscribe(
      () => {
        this.minimizeSidebar();
      }
    );

    this.listTitles = this.communicationService.getMenuList();

    this.communicationService.getMenuList().forEach((item) => {
      if (item.type == 'link') {
        this.quickActionList.push({
          icon: item.icontype,
          link: item.path,
          text: item.title,
          type: '',
        });
      } else if (item.type == 'sub') {
        item.children.forEach((child) => {
          this.quickActionList.push({
            link: `${item.path}/${child.path}`,
            text: child.title,
            type: child.type,
            ab: child.ab,
          });
        });
      }
    });

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();
        const $layer = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
          $layer.remove();
        }
      });

    this.angularFireMessaging.messages.subscribe((data) => {
      console.log('Push Notification received ', data);
      this.notificationService
        .getUserNotification()
        .subscribe((notifications) => {
          this.notification = notifications;
        });
    });

    this.notificationService
      .getUserNotification()
      .subscribe((notifications) => {
        this.notification = notifications;
      });
  }
  // onResize(event) {
  //   if ($(window).width() > 991) {
  //     console.log("big screen");
  //     return false;
  //   }
  //   console.log("small");
  //   return true;
  // }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    // $('html').removeClass('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      if ($toggle) $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (let i = 0; i < this.listTitles.length; i++) {
      if (
        this.listTitles[i].type === 'link' &&
        this.listTitles[i].path === titlee
      ) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === 'sub') {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle =
            this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
          // console.log(subtitle)
          // console.log(titlee)
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return 'Dashboard';
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  onQukckAction(path: string) {
    this.quickActionForm.get('search').setValue(null);
    this.router.navigate([path]);
  }

  logout() {
    this.authService.logout('logout');
  }

  onNotification(notification: UserNotification) {
    console.log(notification);
    switch (notification.refDocTypeId) {
      //purchase requisition
      case 91:
        this.router.navigate([
          `/purchase/requisition/edit/${notification.refDocNumber}`,
        ]);
        break;
      //request for quotation
      case 92:
        this.router.navigate([
          `/purchase/request-for-quotation/edit/${notification.refDocNumber}`,
        ]);
        break;
      //vendor quotation
      case 93:
        this.router.navigate([
          `/purchase/vendor-offer/edit/${notification.refDocId}`,
        ]);
        break;
      //purchase order
      case 94:
        this.router.navigate([
          `/purchase/order/edit/${notification.refDocNumber}`,
        ]);
        break;
      default:
        break;
    }
  }
}
