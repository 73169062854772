<h4 mat-dialog-title>{{formname}} </h4>
<h4 mat-dialog-title> </h4>
<h4 mat-dialog-title></h4>
<h4 mat-dialog-title>{{slotnmae}} </h4>
<div class="mb-2" mat-dialog-content>
  <div>
   
    <div class="table-responsive" *ngIf="istransdtl">
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            
            <th>TransactionNo</th>
            <th>{{ "common.product" | translate }}</th>
            <th>Status</th>
            <th>{{ "common.quantity" | translate }}</th>
          </tr>
        </thead>
        <tbody class="table-data">
          <tr class="bg-light" >
            <td style="vertical-align: middle">
              GRN00001
            </td>
            <td style="vertical-align: middle">
              Euro Ohne Rand
            </td>
            <td style="vertical-align: middle">
                Awaiting Inspection
              </td>
              <td style="vertical-align: middle">
              15
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive" style="height: 300px; overflow-y: scroll;" *ngIf="isstocksheet" >
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            
         
            <th>{{ "common.product" | translate }}</th>
            <th>Status</th>
            <th>In Stock</th>
          </tr>
        </thead>
        <tbody class="table-data" *ngFor="let item of FilteredWareHouseStockList">
          <tr class="bg-light" >
            <td style="vertical-align: middle">
              {{ item.productName }}
            </td>
            <td style="vertical-align: middle">
              {{ item.statusName }}
            </td>
            <td style="vertical-align: middle">
              {{ item.inStockQty }}
              </td>
             
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-right mt-4">
      <button
        class="btn-pp"
        type="button"
        (click)="onNoClick()"
        color="accent"
        mat-raised-button
        mat-dialog-close
      >
        {{ "common.close" | translate }}
      </button>
    </div>
  </div>
</div>
