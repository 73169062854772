import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { settings } from './../../settings/settings';
import { User } from '../../models/user/user';
import { UserRegistrationStatus } from '../../enum/User/user-registration-status';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _hasStore: boolean = null;
  constructor(
    private http: HttpClient,
    private router:Router
  ) {
  }

  getProfile() {
    const requestUrl = '/api/User/GetUserByToken';
    return this.http.get<User>(requestUrl).pipe(
      map((data : any)=>{
        const user : User ={
          userId : data.userId,
          companyId : data.companyId,
          companyName : data.companyName,
          userName:data.userName,
          whid:data.whid
        };
        return user
      })
    );
  }

  getUserPublicProfile() {
    const requestUrl = '/api/User/PublicProfile';
    return this.http.get<User>(requestUrl);
  }

  private currentUser(): User {
    return JSON.parse(localStorage.getItem(settings.currentUser)) as User;
  }

}
