<div class="container-fluid">
    <pp-loading [show]="loading" [message]="'Loading...'"></pp-loading>
    <div class="row">
        <div class="col-12">
        <mat-card class="mb-5 mt-2">
        
          <div class="row">
            <ul class="breadcrumb mb-3">
                <li>Storage Location Dashboard</li>
              
            </ul>
            <div class="col-md-6">
             
            </div>
           
            <div class="col-md-2">
                <button
                  
                  type="button"
                  class="btn-next btn-pp mb-4"
                  mat-flat-button
                  color="accent"
                  (click)="createStorageLocation(0)"
                >
                {{ "common.createstoragelocation" | translate }}
                </button> 
              </div>
          </div>
       
        <div class="col-xxxl-9 col-xxl-10 col-xl-11 mt-2">

           
            <!--      Wizard container        -->
            <div class="wizard-container">

                <div class="card card-wizard mt-0" data-color="purple" id="wizardProfile">
                   
                       
                      
                    
                       <div class="row">
                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Select warehouse</mat-label>
                            <mat-select
                              name="warehouse"
                              [(value)]="warehouseSelected"
                              (selectionChange)="onwarehouseChange($event)"
                            >
                              <mat-option *ngFor="let dep of warehouseDD" [value]="dep.whid">
                                {{ dep.whname }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                   
              
                      </div>
                      
                      <div class="row">
                                       
                                <div class="col-sm-4" style="position: relative;" *ngFor="let loc of warehouselocationDD"   
                                  >
                                    <div class="card card-stats"  >
                                        <div class="card-header card-header-success card-header-icon">
                                            <div class="card-icon" >
                                                <i class="mr-auto">   </i>
                                            </div>
                                            
                                            <strong class="card-category">{{ loc.locationName}}</strong> 
                                            
                                            
                                       
                                            <div class="card-footer">
                                                <div class="text-left" >
                                                    <button mat-button color="primary"  (click)="openStorageocationSlots(loc.whid ,loc.id )">Slot Details</button>
                                                  
                                                    
                                                </div>
                                                <div class="stats">
                                                    <i class="material-icons"></i> {{ loc.locationNoCount}}
                                                </div>
                                              
                                                <div class="text-left">
                                                   
                                                </div>
                                                <div class="text-right">
                                                    <button title="Add New" mat-icon-button >
                                                      
                                                        <button mat-icon-button matTooltip="Click to Edit" (click)="EditStorageLocation(loc.id)" class="iconbutton"
                                                        color="primary" >  {{ "common.edit" | translate }}
                                                       
                                                      </button>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                        </div>
                          
               
                
                </div>
            </div>
            <!-- wizard container -->
        </div>
    </mat-card>
        </div>
    </div>
</div>