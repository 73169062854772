<form (isValid)="cancelRequisition($event)" [formGroup]="requisitionCancelForm">
    <h4 mat-dialog-title>{{data}}</h4>
    <div class="mb-2" mat-dialog-content>
        <div class="row">
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Reason</mat-label>
                <mat-select [error]="reasonCode" formControlName="reason" name="reasonCode">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let code of reasonCodes" [value]="code.reasonCodeId">
                        {{code.reasonCode}}
                    </mat-option>
                </mat-select>
                <mat-error #reasonCode></mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Remarks</mat-label>
                <textarea [error]="remarks" formControlName="remarks" matInput placeholder="Remarks if any"></textarea>
                <mat-error #remarks></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions class="pull-right">
        <button type="button" class="btn-pp" (click)="onNoClick()" mat-raised-button>No</button>
        <button type="submit" class="btn-pp" color="accent" mat-raised-button>Yes</button>
    </div>
</form>