import { Component, OnInit } from '@angular/core';
import { settings } from 'src/app/settings/settings';

@Component({
  selector: 'pp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appVersion: string;
  test: Date = new Date();
  constructor() { }

  ngOnInit(): void {
    this.appVersion = settings.appVersion;
  }

}
