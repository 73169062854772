import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { settings } from '../../settings/settings';
import { FileInfo } from '../../models/file/file';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private pageTitle: Title,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {


  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };

  isValidEmail(email: string): boolean {
    let patt = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return patt.test(email);
  }

  handleFormError(error: HttpErrorResponse, form: FormGroup) {

    switch (error.status) {
      case 0:
        form.setErrors({ message: settings.httpErrorMessage.httpFailure });
        break;
      case 300:
        form.setErrors({ message: error.error });
        break;
      case 400:
        var errmsg = {};
        var message = settings.httpErrorMessage.badRequest;
        if (error.error?.errors) {
          if (error.error?.title) {
            message = error.error.title;
          }
          errmsg = error.error.errors;
        } else {
          errmsg = error.error;
        }
        form.setErrors({ message: message, errorMessages: errmsg });
        break;

      case 401:
        form.setErrors({ message: "Un authorized" });
        break;
      case 404:
        form.setErrors({ message: "Something went wrong" });
        break;
      case 406:
        form.setErrors({ message: "Not Acceptable", errorMessages: error.error });
        break;
      case 500:
        form.setErrors({ message: settings.httpErrorMessage.internalServerError, errorMessages: error.error });
        break;
      default:

        break;
    }
    console.error(error);
  }

  async readFile(input: HTMLInputElement): Promise<FileInfo | Array<FileInfo>> {
    return new Promise(async (resolve, reject) => {
      var data: Array<FileInfo> = [];
      if (input.files.length) {
        if (input.hasAttribute('multiple')) {
          for (var i = 0; i < input.files.length; i++) {
            data.push(await this.readFileInfo(input.files[i]));
          }
          resolve(data);
        }
        else {
          resolve(await this.readFileInfo(input.files[0]));
        }
      }
      else {
        reject("no files found")
      }
    })
  }

  private async readFileInfo(file: File): Promise<FileInfo> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          let fileInfo: FileInfo = {
            data: event.target.result,
            fileName: file.name,
            size: file.size,
            type: file.type
          }
          resolve(fileInfo);
        }
      }
      else {
        reject("file not found");
      }
    });
  }

  parseDate(date: Date) {
    return new Date(moment(date).local().format('YYYY-MM-DD'));
  }

  getSqlDateTime(date: any, hour: any, min: any) {
    if (hour == "") {
      hour = 0;
    }
    if (min == "") {
      min = 0;
    }
    let mntDate = moment(date).format('YYYY-MM-DD');
    let mntDateTime = moment(mntDate + " " + hour + ":" + min + ":" + "00", 'YYYY-MM-DD hh:mm:ss')
    return moment(mntDateTime).format('YYYY-MM-DD hh:mm:ss');
  }

}
