import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  WarehouseLocationSelection,
  WarehouseService,
  WHLocationslotModel,
  WHStockSheetModel,
  WHLocationModel,
} from 'src/app/services/warehouse/warehouse.service';

@Component({
  selector: 'pp-alltransactionsdtl',
  templateUrl: './alltransactionsdtl.component.html',
  styleUrls: ['./alltransactionsdtl.component.scss']
})
export class AlltransactionsdtlComponent implements OnInit {
  data
  FilteredWareHouseStockList : Array<WHStockSheetModel>=[];
  isstocksheet:boolean =true;
  stocksheetList: Array<WHStockSheetModel>=[];
  istransdtl:boolean =true;
  slotnmae:string;
  formname:string;
  constructor(
 
    private warehouseService: WarehouseService,
    @Inject(MAT_DIALOG_DATA) public SlotID: any,
   @Inject(MAT_DIALOG_DATA) public SlotNum: any,
   // @Inject(MAT_DIALOG_DATA) public whid: any,
    public dialogRef: MatDialogRef<AlltransactionsdtlComponent>
  ) { 
   
  }
  ngOnInit(): void {
if(this.SlotID.SlotID>0)
{
  this.formname="Slot Bestand Details"
  this.isstocksheet=true;
  this.istransdtl=false;
  console.log('this.SlotID.SlotID',this.SlotID.SlotID);
 this.warehouseService.GetWareHouseStockSheetWHId(2562).subscribe( (datastock) => {
 this.stocksheetList = datastock;
  this.FilteredWareHouseStockList=this.stocksheetList.filter(i=>i.SlotId==this.SlotID.SlotID && i.inStockQty>0 || i.slotId==this.SlotID.SlotID && i.inStockQty>0)
  console.log(this.FilteredWareHouseStockList);
 this.formname="Slot Bestand Details";
 this.slotnmae=this.SlotNum.SlotNum;
  })
}
else
{
  this.formname="Slot Transaction Details"
  this.istransdtl=true;
  this.isstocksheet=false;
}

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
