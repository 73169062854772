import { Component, OnInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {FormsModule} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  WarehouseDropDown,

  BusinessPartnerService,
} from 'src/app/services/business-partner/business-partner.service';
import { MatSelectChange } from '@angular/material/select';
import {
  WHStockSheetModel,
  WarehouseService,
  WHLocationslotModel,
  WarehouseLocationSelection,
} from 'src/app/services/warehouse/warehouse.service';
import { MatDialog } from '@angular/material/dialog';
import {
 
  Input,
  
  
} from '@angular/core'; 
import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { SlalldetaildialogComponent } from './sldetail/slalldetaildialog/slalldetaildialog.component';
import { WhstockslotmanagementComponent } from './shared/alltransactionsdtl/whstockslotmanagement/whstockslotmanagement.component';
@Component({
  selector: 'pp-sldashborad',
  templateUrl: './sldashborad.component.html',
  styleUrls: ['./sldashborad.component.scss'],

  providers: [
    MatTabGroup,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    FormsModule,
    TranslatePipe,
    MatFormFieldModule,
    
    MatDialog
  ],

})
export class SldashboradComponent implements OnInit {

  @Input('warehouseId') warehouseId: number;
  warehouselocationDD: Array<WarehouseLocationSelection> = [];
  warehouseDD: Array<WarehouseDropDown> = [];
  
  constructor(
    private warehouseService: WarehouseService,
    private translatePipe: TranslatePipe,
    private dialog: MatDialog,
    private businessPartnerService: BusinessPartnerService
  ) { }
 
  loading: boolean = false;
  warehouseSelected: number;
  updatedwhslotdata: Array<WHLocationslotModel> = [];
  onwarehouseChange(event: MatSelectChange) {
    this.warehouseSelected = event.value;
    this.loading = true;

    if (event.value > 0) {
      this.warehouseService
        .DistinctWarehouseLocationByWhId(event.value)
        .subscribe((data) => {
          this.warehouselocationDD = data;
          this.loading = false;
        });
    }
  }
  ngOnInit(): void {

    this.loading = true;
    this.businessPartnerService.warehouseDropDown().subscribe((data) => {
      this.warehouseDD = data;
      this.warehouseSelected = data[2].whid;
      this.warehouseService
    .GetWareHouseLocSlotsWHId(this.warehouseSelected)
    .subscribe((Data) => {
     this.updatedwhslotdata=Data;
    })
debugger;
        if (data[2].whid > 0) {
       this.warehouseService
         .DistinctWarehouseLocationByWhId(data[2].whid)
          .subscribe((data) => {
            this.warehouselocationDD = data;

           console.log(this.warehouselocationDD);
         //  let location = this.warehouselocationDD.forEach((element) => {
            //console.log(element.id);
            //  this.updatedwhslotdata.filter(x=>x.locationId==element.id)
           
           //   this.warehouseService
          ///    .GetWareHouseLocSlotsWHIdandLocationID(this.warehouseSelected,element.id).subscribe((sldata)=>{
             //   element.locationNoCount=this.updatedwhslotdata.length;
         // console.log('length',this.updatedwhslotdata.length);
           // // });
          //  });
            
           // this.warehouselocationDD = data;
           // this.locationSelected = data[0].id;
          });
      }
      this.loading = false;

  
    });



  }
  openStorageocationSlots(whid:any , LName:any ) {
   // this.warehouseService
   // .GetWareHouseLocSlotsWHIdandLocationID(this.warehouseSelected,LName)
   // .subscribe((dataL) => {
    
   //   console.log('****', dataL)
   // console.log('****', LName)
   // console.log('****', this.warehouseSelected)
  //});
    const DailogRef = this.dialog.open(SlalldetaildialogComponent, {
      width: '60%',
      maxWidth: '100%',
      maxHeight: '100%',
      role: 'alertdialog',
      disableClose: true,
      data: { LName: LName, whid: this.warehouseSelected,type:"SlotDtl" },
    //  data:LName,whid,
    });
  

   // openStorageocationSlots(whid:any , LName:any ) {
    //  this.warehouseService
     // .GetWareHouseLocSlotsWHIdandLocationID(this.warehouseSelected,LName)
     // .subscribe((dataL) => {
       
     //   console.log('****', dataL)
    //  console.log('****', LName)
    //  console.log('****', this.warehouseSelected)
     
    //  const DailogRef = this.dialog.open(WhstockslotmanagementComponent, {
    //    width: '1200px',
    //    maxWidth: '100%',
      //  maxHeight: '50%',
      //  role: 'alertdialog',
      //  disableClose: true,
       // data: { LName: LName, whid: this.warehouseSelected },
      //  data:LName,whid,
     // });
     // });
 
}
EditStorageLocation(locationId:number ) {
    
  //console.log('****', locationid)
  const DailogRef = this.dialog.open(SlalldetaildialogComponent, {
    width: '80%',
    height:'90%',
    maxWidth: '100%',
    maxHeight: '100%',
    role: 'alertdialog',
    disableClose: true,
    data: { LName: locationId, whid: this.warehouseSelected,type:"Change" },
  //  data:LName,whid,
  });
  
}
createStorageLocation(locationId:number ) {
    
  //console.log('****', locationid)
  const DailogRef = this.dialog.open(SlalldetaildialogComponent, {
    width: '80%',
    height:'90%',
    maxWidth: '100%',
    maxHeight: '100%',
    role: 'alertdialog',
    disableClose: true,
    data: { LName: 0, whid: this.warehouseSelected,type:"Create" },
  //  data:LName,whid,
  });
  
}
}
