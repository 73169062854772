<h3> <i class="fa fa-bell-o"></i> Do you want to enable push notification ?</h3>
<mat-nav-list>
  <a href="javascript:void(0)" mat-list-item (click)="ans(true)">
    <span mat-line>Yes <i class="fa fa-bell-o"></i></span>
    <span mat-line>Enable Push Notification</span>
  </a>
  <a href="javascript:void(0)" mat-list-item (click)="ans(false)">
    <span mat-line>No <i class="fa fa-bell-slash-o"></i></span>
    <span mat-line>I don't want</span>
  </a>
</mat-nav-list>