<button
  (click)="minimizeSidebar()"
  class="btn-arrow d-none d-lg-block"
  mat-icon-button
  mat-raised-button
>
  <i
    class="fa"
    [ngClass]="minimizedSidebar ? 'fa-arrow-right' : 'fa-arrow-left'"
  ></i>
</button>
<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/logo/sidebar-logo.png" />
    </div>
  </a>
  <a routerLink="/" class="simple-text logo-normal">
    <img src="/assets/logo/brand-text.png" />
  </a>
</div>
<div class="sidebar-wrapper">
  <div class="user date-time">
    <!-- 28 Aug.2020 ; 20:51:23 Hrs -->
    <i class="fa fa-clock"></i>
    {{ currentDate | amDateFormat: "DD MMM. YYYY ; HH:mm:ss" }} Hrs
  </div>

  <div class="searchMenu m-1">
    <!-- <form class="navbar-form px-1" [formGroup]="quickActionForm">
      <input
        formControlName="search"
        #quickAction
        autocomplete="off"
        type="text"
        class="form-control"
        placeholder="Search here...."
        (keyup)="search(quickAction.value)"
      />
      <i class="material-icons">search</i>
    </form> -->

    <form class="navbar-form" [formGroup]="quickActionForm">
      <!-- <div class="input-group no-border"> -->
      <input
        formControlName="search"
        #quickAction
        autocomplete="off"
        type="text"
        class="form-control"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        placeholder="Search"
      />
      <i class="material-icons">search</i>
      <!-- </div> -->
      <ng-container *ngIf="quickActionResult | async as searchResult">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayWidth]="233"
          [cdkConnectedOverlayOpen]="searchResult.length"
        >
          <ul
            id="list"
            *ngIf="searchResult.length"
            class="quick-action-list list-unstyled"
            style="background-color: rgb(244, 244, 244)"
          >
            <li
              *ngFor="let item of searchResult"
              (click)="onQukckAction(item.link)"
            >
              <i *ngIf="item.icon" class="material-icons">{{ item.icon }}</i>
              <span class="badge badge-danger" *ngIf="item.ab">{{
                item.ab
              }}</span>
              <span class="badge badge-primary" *ngIf="item.type">{{
                item.type
              }}</span>
              {{ item.text }}
            </li>
          </ul>
        </ng-template>
      </ng-container>
    </form>
  </div>

  <div class="user">
    <div class="photo">
      <!-- <img src="./assets/img/faces/avatar.jpg" /> -->
      <i class="fa fa-2x fa-user-circle text-primary"></i>
    </div>
    <div
      class="user-info"
      routerLinkActive="active"
      #rlam="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a
        [attr.aria-expanded]="rlam.isActive"
        data-toggle="collapse"
        href="#collapseExample"
        class="collapsed"
      >
        <span>
          {{ currentUser.userName }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" routerLinkActive="show" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a routerLink="/master/profile-edit" class="nav-link">
              <!-- <span class="sidebar-mini">MP</span> -->
              <span class="sidebar-normal">My Profile</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <!-- <span class="sidebar-mini">EP</span> -->
              <span class="sidebar-normal">Edit Profile</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <!-- <span class="sidebar-mini">S</span> -->
              <span class="sidebar-normal">Settings</span>
            </a>
          </li>
          <li
            class="nav-item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a routerLink="/format-compose" class="nav-link">
              <!-- <span class="sidebar-mini">FMT</span> -->
              <span class="sidebar-normal">Format</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/master/changepass" class="nav-link">
              <!-- <span class="sidebar-mini">MP</span> -->
              <span >Change Password</span>
            </a>
          </li>
        </ul>
       
      </div>
      <ul class="nav">
      <li>
        <a (click)="logout()" href="javascript:void(0)" class="nav-link">
          <mat-icon>exit_to_app</mat-icon>
          <span style="font-size: 17px;">{{'menu.logout' | translate}}</span>
        </a>
      </li>
    </ul>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <!-- <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Quick Actions">
          <button mat-flat-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form> -->
    <ul class="nav navbar-nav nav-mobile-menu">
      <!-- <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Stats</span>
          </p>
        </a>
      </li> -->
      <li class="nav-item dropdown">
        <a
          class="nav-link"
          href="#pablo"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="material-icons">notifications</i>
          <span *ngIf="notification.length" class="notification">{{
            notification.length
          }}</span>
          <p>
            <span class="d-lg-none d-md-block">Notifications</span>
          </p>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right notification-details"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a
            *ngFor="let item of notification"
            class="dropdown-item"
            href="javascript:void(0)"
          >
            {{ item.message }} &nbsp;&nbsp; <br />
            <span class="notification-time">
              <i class="fa fa-clock-o"></i>
              {{ item.transDatetime | amTimeAgo }}</span
            >
          </a>
        </div>
      </li>
      <li class="nav-item">
        <!-- <a class="nav-link" href="#pablo">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Account</span>
          </p>
        </a> -->
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li
      routerLinkActive="active"
      #rla="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngFor="let menuitem of menuItems"
      class="nav-item"
    >
      <!--If is a single link-->
      <a
        matRipple
        [routerLink]="[menuitem.path]"
        *ngIf="menuitem.type === 'link'"
        class="nav-link"
        [style.color]="
          quickAction?.value?.toLowerCase() === menuitem?.title?.toLowerCase()
            ? 'rgb(28, 102, 236)'
            : ''
        "
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        [attr.aria-expanded]="rla.isActive"
        data-toggle="collapse"
        href="#{{ menuitem.collapse }}"
        *ngIf="menuitem.type === 'sub'"
        (click)="updatePS()"
        class="nav-link"
        [style.color]="
          quickAction?.value?.toLowerCase() === menuitem?.title?.toLowerCase()
            ? 'rgb(28, 102, 236)'
            : ''
        "
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        routerLinkActive="show"
        *ngIf="menuitem.type === 'sub'"
      >
        <ul class="nav">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
            class="nav-item"
          >
            <a
              [routerLink]="[menuitem.path, childitem.path]"
              class="nav-link"
              [style.color]="
                quickAction?.value?.toLowerCase() ===
                childitem?.title?.toLowerCase()
                  ? 'rgb(28, 102, 236)'
                  : ''
              "
            >
              <span class="sidebar-mini">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <!--If is a single link-->
      <!-- <a matRipple (click)="logout()" href="javascript:void(0)" class="nav-link">
        <i class="material-icons">exit_to_app</i>
        <p>{{'menu.logout' | translate}}</p>
      </a> -->
    </li>
  </ul>
</div>
