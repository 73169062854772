import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {

  constructor(
    private http: HttpClient
  ) { }

  BusinessPartnerList(): Observable<Array<BusinessPartnerListModel>> {
    const url = "/api/BusinessPartner/GetAll/10000/1";
    return this.http.get<Array<BusinessPartnerListModel>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: BusinessPartnerListModel = {
            // $id: string;
            bpid: item.bpid,
            bpcode: item.bpcode,
            bpgroupName: item.bpgroupName,
            bpname: item.bpname,
            groupType: item.groupType,
            bpgroupId: item.bpgroupId,
            address: item.address,
            city: item.city,
            stateCd: item.stateCd,
            countryCd: item.countryCd,
            pinCode: item.pinCode,
            phone1: item.phone1,
            email: item.email,
            currencyCd: item.currencyCd,
            extCustNo: item.extCustNo,
            matchCode: item.matchCode,
            contactName: item.contactName,
            lstVatNo: item.lstVatNo,
            vat: item.vat,
            work: item.work,
            supplierClass: item.supplierClass,
          }
          return data;
        })
      })
    );
  }

  BusinessPartnerDropdown(): Observable<Array<BusinessPartnerDropdown>> {
    const url = "/api/BusinessPartner/GetAllForSelection";
    return this.http.get<Array<BusinessPartnerDropdown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: BusinessPartnerDropdown = {
            BusinessPartnerCd: item.BusinessPartnerCd,
            prodName: item.prodName
          }
          return data;
        })
      })
    );
  }

  BusinessPartnerGroupDropdown(): Observable<Array<BusinessPartnerGroupDropdown>> {
    const url = "/api/BPartnerGroup/GetAllForSelection";
    return this.http.get<Array<BusinessPartnerGroupDropdown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: BusinessPartnerGroupDropdown = {
            bpgroupId: item.bpgroupId,
            bpgroupName: item.bpgroupName
          }
          return data;
        })
      })
    );
  }

  CurrencyDropdown(): Observable<Array<CurrencyDropdown>> {
    const url = "/api/Currency/GetAll/10000/1";
    return this.http.get<Array<CurrencyDropdown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: CurrencyDropdown = {
            currencyCd: item.currencyCd,
            currencySd: item.currencySd,
            currencyD: item.currencyD,
            active: item.active,
            visible: item.visible
          }
          return data;
        })
      })
    );
  }

  createBusinessPartner(data: BusinessPartnerCreateModel) {
    console.log(data);
    const url = "/api/BusinessPartner/CreateBusinessPartner";
    return this.http.post<any>(url, data);
  }

  updateBusinessPartner(data: BusinessPartnerCreateModel) {
    console.log(data);
    const url = "/api/BusinessPartner/UpdateBusinessPartner";
    return this.http.put<any>(url, data);
  }

  getRetailers() {
    const url = "/api/BusinessPartner/GetRetailers";
    return this.http.get<Array<Retailers>>(url);
  }
  getBusinessPartnersRelation(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetRelation/${businessPartnerId}`;
    return this.http.get<Array<RelationBusinessPartner>>(url);
  }
  getBusinessPartnersRelationWithSelf(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetRelation/${businessPartnerId}/true`;
    return this.http.get<Array<RelationBusinessPartner>>(url);
  }
  getAddressbyBusinesspartnerById(businessPartnerId: string) {
    const url = `/api/BusinessPartner/GetBusinessPartnerById/${businessPartnerId}`;
    return this.http.get<BusinessPartnerCreateModel>(url);
  }

  getAddressbyBusinesspartner(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetAddress/${businessPartnerId}`;
    return this.http.get<Array<BusinessPartnerAddress>>(url);
  }

  createBusinessPartnerAddress(data: BusinessPartnerAddress) {
    console.log(data);
    const url = "/api/BusinessPartner/CreateBPAddress";
    return this.http.post<any>(url, data);
  }
  deleteBusinessPartnerAddress(addressId: number) {
    // console.log(data);
    const url = `/api/BusinessPartner/DeleteBPAddress/${addressId}`;
    return this.http.delete<any>(url);
  }

  getProductbyBusinesspartner(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetProduct/${businessPartnerId}`;
    return this.http.get<Array<BusinessPartnerProduct>>(url);
  }

  createBusinessPartnerProduct(data: BusinessPartnerProduct) {
    console.log(data);
    const url = "/api/BusinessPartner/CreateBPProduct";
    return this.http.post<any>(url, data);
  }

  deleteBusinessPartnerProduct(bpId: number, productId: number) {
    // console.log(data);
    const url = `/api/BusinessPartner/DeleteBPProduct/${bpId}/${productId}`;
    return this.http.delete<any>(url);
  }

  //#region Business Partner Contact related

  getContactbyBusinesspartner(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetContact/${businessPartnerId}`;
    return this.http.get<Array<BusinessPartnerContact>>(url);
  }

  createBusinessPartnerContact(data: BusinessPartnerContact) {

    const url = "/api/BusinessPartner/CreateBPContact";
    return this.http.post<any>(url, data);
  }

  deleteBusinessPartnerContact(contactId: number) {
    const url = `/api/BusinessPartner/DeleteBPContact/${contactId}`;
    return this.http.delete<any>(url);
  }

  departmentDropDown(): Observable<Array<DepartmentDropDown>> {
    const url = "/api/Department/GetAllSelection";
    return this.http.get<Array<DepartmentDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: DepartmentDropDown = {
            departmentId: item.departmentId,
            departmentD: item.departmentD
          }
          return data;
        })
      })
    );
  }

  //#endregion

  //#region Business Partner Vehicle Info

  createBusinessPartnerVehicleInfo(data: BusinessPartnerVehicle) {

    const url = "/api/BusinessPartner/CreateBPVehicle";
    return this.http.post<any>(url, data);
  }

  deleteBusinessPartnerVehicleInfo(bpId: number, vehiclecd: number) {
    const url = `/api/BusinessPartner/DeleteBPVehicle/${bpId}/${vehiclecd}`;
    return this.http.delete<any>(url);
  }

  vehicleDropDown(): Observable<Array<VehicleDropDown>> {
    const url = "/api/Vehicle/GetAllSelection";
    return this.http.get<Array<VehicleDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: VehicleDropDown = {
            vehiclecd: item.vehiclecd,
            vehicleName: item.vehicleName
          }
          return data;
        })
      })
    );
  }

  //#endregion

  //#region Business Partner Relation Info

  getBusinessPartnerRelationInfo(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetRelation/${businessPartnerId}/${false}`;
    return this.http.get<Array<BusinessPartnerRelation>>(url);
  }
  createBusinessPartnerRelationInfo(data: BusinessPartnerRelation) {

    const url = "/api/BusinessPartner/CreateBPRelation";
    return this.http.post<any>(url, data);
  }
  deleteBusinessPartnerRelationInfo(bpId: number, relationBpid: number) {
    const url = `/api/BusinessPartner/DeleteBPRelation/${bpId}/${relationBpid}`;
    return this.http.delete<any>(url);
  }

  bpRelationDropDown(): Observable<Array<RelationDropDown>> {
    const url = "/api/BusinessPartner/GetCreditors";
    return this.http.get<Array<RelationDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: RelationDropDown = {
            id: item.id,
            retailerName: item.retailerName
          }
          return data;
        })
      })
    );
  }
  bpRelationTypeDropDown(): Observable<Array<RelationTypeDropDown>> {
    const url = "/api/CoreMaster/GetRelationTypeForSelection";
    return this.http.get<Array<RelationTypeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: RelationTypeDropDown = {
            relationType: item.relationType,
            relationTypeDesc: item.relationTypeDesc
          }
          return data;
        })
      })
    );
  }


  //#endregion

  //#region Business Partner Depot Info

  getBusinessPartnerDepotInfo(businessPartnerId: number) {
    const url = `/api/BusinessPartner/GetWarehouse/${businessPartnerId}`;
    return this.http.get<Array<BusinessPartnerDepot>>(url);
  }

  createBusinessPartnerDepotInfo(data: BusinessPartnerDepot) {

    const url = "/api/BusinessPartner/CreateBPWarehouse";
    return this.http.post<any>(url, data);
  }

  deleteBusinessPartnerDepotInfo(bpId: number, whid: number, haulierId: number) {
    const url = `/api/BusinessPartner/DeleteBPWarehouse/${bpId}/${whid}/${haulierId}`;
    return this.http.delete<any>(url);
  }

  warehouseDropDown(): Observable<Array<WarehouseDropDown>> {
    const url = "/api/Warehouse/GetWarehouseForSelection";
    return this.http.get<Array<WarehouseDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: WarehouseDropDown = {
            whid: item.whid,
            whname: item.whname
          }
          return data;
        })
      })
    );
  }
  haulierDropDown(): Observable<Array<HaulierDropDown>> {
    const url = "/api/BusinessPartner/GetHauliers";
    return this.http.get<Array<HaulierDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: HaulierDropDown = {
            id: item.id,
            hauilierName: item.hauilierName
          }
          return data;
        })
      })
    );
  }
  //#endregion

  //#region Business Partner User

  getUserList(businessPartnerId: number) {
    
    if(businessPartnerId>0){
      const url = `/api/User/GetUserByBP/${businessPartnerId}`;
      return this.http.get<Array<BPUser>>(url);

    }else{

      const url = `/api/User/GetAll/${1000}/${1}`;
      return this.http.get<Array<BPUser>>(url);
    }
  }
    
  createBPUser(data: BPUser) {
    const url = "/api/User/CreateUser";
    return this.http.post<any>(url, data);
  }

  editUser(data: BPUser){
    const url = "/api/User/UpdateUser";
    return this.http.put<any>(url, data);
  }

  bpUserTypeDropDown(): Observable<Array<UserTypeDropDown>> {
    const url = "/api/CoreMaster/GetUserTypeForSelection";
    return this.http.get<Array<UserTypeDropDown>>(url).pipe(
      map(data => {
        return data.map(item => {
          const data: UserTypeDropDown = {
            userTypeId: item.userTypeId,
            userTypeD: item.userTypeD
          }
          return data;
        })
      })
    );
  }

  //#endregion

}

export interface UserTypeDropDown{
  userTypeId:number,
  userTypeD:string
}

export interface BPUser{
  userCatId: number,
  userTypeId: number,
  userType: string,
  whid: number,
  bpid: number,
  bpName: string,
  companyId: number,
  languageCd: number,
  language:number,
  userName:string,
  companyName:string,
  whName:string,
  userCategory:string,
  phone1:string,
  phone2:string,
  fax:string,
  active:boolean,
  email:string,
  userId:number,
  passWord:string,
  secQues:string,
  secAns:string
}

export interface BusinessPartnerRelation{
  bpid:number,
  relationBpid:number,
  relationBpName:string,
  relationType:string,
  relationTypeDesc:string,
  refNo:number,
  bpAliasName:string,
  inboundTransitTime:number,
  inboundType:string,
 

}

export interface RelationDropDown{
  id: number,
  retailerName: string
}
export interface RelationTypeDropDown{
  relationType: string,
  relationTypeDesc: string
}

export interface BusinessPartnerDropdown {
  BusinessPartnerCd: string;
  prodName: string;
}

export interface Retailers {
  retailerName: string;
  retailerCode: string;
  groupName: string;
  id: number;
  groupID: number;
}

export interface RelationBusinessPartner {
  bpaliasName: string;
  inboundTransitTime: Date;
  inboundType: string;
  refNo: string;
  relationBpName: string;
  relationBpid: number;
  relationType: string;
  relationTypeDesc: string;
}

export interface BusinessPartnerAddress {
  addressId: number;
  bpid: number;
  addressType: string;
  addressName: string;
  addressDes: string;
  city: string;
  stateCd: string;
  countryCd: string;
  pinCode: string;
  phone1: string;
  phone2: string;
}

export interface BusinessPartnerProduct {

  bpid: number,
  productId: number,
  productCd: string,
  capping: number,
  cappingMinQty: number,
  cappingMaxQty: number,
  minQty: number,
  maxQty: number,
  push: number,
  productName:string,
  priority:number

}


export interface BusinessPartnerContact {
  bpid: number,
  id: number,
  contactId: number,
  contactName: string,
  departmentId: number,
  departmentname: string,
  email: string,
  fax: number,
  mobile: number,
  phone: number

}

export interface BusinessPartnerVehicle {
  bpid: number,
  vehiclecd: string,
  pref: number

}

export interface DepartmentDropDown {
  departmentId: number,
  departmentD: string
}
export interface VehicleDropDown {
  vehiclecd: number,
  vehicleName: string
}

export interface BusinessPartnerDepot {
  bpid: number,
  whid: number,
  haulierId: number,
  pref: number,
  transitTime: number

}

export interface WarehouseDropDown {
  whid: number,
  whname: string
}
export interface HaulierDropDown {
  id: number,
  hauilierName: string
}




export interface BusinessPartnerGroupDropdown {
  // $id: string,
  bpgroupId: number,
  bpgroupName: string
}

export interface CurrencyDropdown {
  // $id: string,
  currencyCd: string,
  currencySd: string,
  currencyD: string,
  active: boolean,
  visible: boolean
}



export interface BPAddress {
  addressId: number;
  bpid: number;
  addressType: string;
  addressName: string;
  addressDes: string;
  city: string;
  stateCd: string;
  countryCd: string;
  pinCode: string;
  phone1: string;
  phone2: string;
  fax: string;
  pref: number;
}

export interface BPContact {
  contactId: number;
  bpid: number;
  contactName: string;
  phone: string;
  mobile: string;
  email: string;
  departmentId: number;
  fax: string;
  pref: number;
  lmby: string;
  lmdt: Date;
  datarowid: string;
  datarowversion: string;
}

export interface BPGroup {
  bpgroupId: number;
  bpgroupName: string;
  groupType: string;
  industryId: number;
  isHaulier: boolean;
  isSupplier: boolean;
  isRetailer: boolean;
  isDirect: boolean;
}

export interface BPLSP {
  bpid: number;
  lspid: number;
}

export interface BPPricing {
  id: number;
  srno: number;
  bpid: number;
  moveType: string;
  pricingtypecd: number;
  prodratetypecd: number;
  hireTypeId: number;
  isPaying: boolean;
  feeapplicable: string;
  invoiceFrequencyId: number;
}

export interface BPProduct {
  bpid: number;
  productId: number;
  productCd: string;
  capping: number;
  cappingMinQty: number;
  cappingMaxQty: number;
  minQty: number;
  maxQty: number;
  push: number;
}

export interface BPRelation {
  bpid: number;
  relationBpid: number;
  relationType: string;
  refNo: string;
}

export interface BPWarehous {
  bpid: number;
  whid: number;
  haulierId: number;
  pref: number;
  transitTime: number;
}

export interface BPVehicle {
  bpid: number;
  vehiclecd: string;
  pref: number;
}

export interface BusinessPartnerCreateModel {
  bpid: number;
  // lspid: number;
  bpcode: string;
  bpname: string;
  // groupType: string;
  bpgroupId: number;
  // address: string;
  // city: string;
  // stateCd: string;
  // countryCd: string;
  // pinCode: string;
  phone1: string;
  phone2: string;
  fax: string;
  email: string;
  invoiceEmail:string;
  currencyCd: string;
  controlAccd: string;
  extCustNo: string;
  // matchCode: string;
  // contactName: string;
  creditDays: number;
  // isHaulier: boolean;
  // direct: boolean;
  // indirect: boolean;
  // lmby: string;
  // lmdt: Date;
  // datarowid: string;
  // datarowversion: string;
  webSite: string;
  lstVatNo: string;
  vat: number;
  // work: string;
  // supplierClass: string;
  // bPAddresses: Array<BPAddress>;
  // bPContacts: Array<BPContact>;
  // bPGroups: Array<BPGroup>;
  // bPLSPs: Array<BPLSP>;
  // bPPricings: Array<BPPricing>;
  // bPProducts: Array<BPProduct>;
  // bPRelations: Array<BPRelation>;
  // bPWarehouses: Array<BPWarehous>;
  // bPVehicles: Array<BPVehicle>;
}

export interface BusinessPartnerListModel {
  // $id: string;
  bpid: number;
  bpcode: string;
  bpgroupName: string;
  bpname: string;
  groupType: string;
  bpgroupId: number;
  address: string;
  city: string;
  stateCd?: any;
  countryCd: string;
  pinCode: string;
  phone1: string;
  email: string;
  currencyCd: string;
  extCustNo: string;
  matchCode: string;
  contactName: string;
  lstVatNo: string;
  vat: number;
  work: string;
  supplierClass: string;
}